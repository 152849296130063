import React  from 'react';
import DragDropQuestion from './DragDropQuestion.js';
import {Stage, Layer, Image} from 'react-konva';
import useImage from 'use-image';

const TestDragDropQuestion = () =>{
 
	const drops = [
		{ 'text' : "Option1", x: 40, y: 540 },
		{ 'text' : "Option2", x: 180, y: 540 },
		{ 'text' : "Option3", x: 320, y: 540 },
		{ 'text' : "Option4", x: 460, y: 540 }];

	const textBoxWidth = 120;
	const textBoxHeight = 40;

	const setup = false;
	
	const image = './network.png' 		//place in images folder
	
	const answer = ['A','B','C','D'];

	const targets = [
		{ x: 90, y: 280 },
		{ x: 90, y: 440 },
		{ x: 620, y: 220 },
		{ x: 620, y: 480 }];

	const question = "Put the labels by the computers."

	  return (
      			<DragDropQuestion 
				question={question}
				image = {image}	
				drops = {drops} 
				targets = {targets}
				textBoxWidth = {textBoxWidth}
				textBoxHeight = {textBoxHeight}
				setupMode =  {setup}  
			/>
	);
}

export default TestDragDropQuestion;
