import  {React, useState, useEffect, useRef} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './PreviewQuestion.css'


const PreviewQuestion = ({question}) => {

	console.log("PreviewQuestions")




//============================================================================================================
// Main Render
	return (	<div>
		
				PreviewQuestions
		
			</div>
		)

}


export default PreviewQuestion;
