import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';

import parse from 'html-react-parser';
import useScrollSpy from 'react-use-scrollspy';
import './test.css';

const url1 = 'http://localhost:5000/tutorialhtml/accesscontrollists/test.htm';
const url2 = 'http://localhost:5000/tutorialhtml/accesscontrollists/test-nav.htm';

const Sample2 = () => {

 	const [htmlContent, setHtmlContent] = React.useState(
    		"<p>html not loaded</p>"
 	 );
	const [htmlNav, setHtmlNav] = React.useState(
    		"<p>html not loaded</p>"
 	 );

  	const sectionRefs = [useRef(null), useRef(null), useRef(null)];

  	const activeSection = useScrollSpy({
   		sectionElementRefs: sectionRefs,
  	  	offsetPx: -80,
  	});


	useEffect(()=>{
		
		let data;
	
		const downloadHtml = async () =>{
//			console.log("downloading html subject:",subject," filename:", filename);	

			console.log(url1 );
				fetch(url1 )
  				.then(function(response) {
				    return response.text();
				  }).then(function(data) {
					console.log(data); // this will be a string
					setHtmlContent(data);
			  });
			
		
			
		};
		downloadHtml();
	
	}, []);

	useEffect(()=>{
	
		console.log(htmlContent );
	
	
	}, [htmlContent, htmlNav]);

const pills = ['One','Two','Three','Four','Five'];

const htmlSections = [

"<h4>One</h4><p>Technical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFFTechnical STUFF</p>",

"<h4>Two</h4><p>More Technical Stuff</p>More Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical StuffMore Technical Stuff</p>",

"<h4>Three</h4><p>Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff</p>",

"<h4>Four</h4><p>Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff</p>",

"<h4>Five</h4><p>Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff Even More Technical Stuff</p>"
];

const NavBar = ({pills}) =>{



	return (
	
	<nav id="topnavbar" className="navbar navbar-light bg-light">
	  <a className="navbar-brand" href="#">Navbar</a>
       <ul className="nav nav-pills">
	   
		{ pills && pills.map((pill)=>{
			return (		
				 <li><a  href={'#' + pill}>{pill}</a></li>
			);
		})};
  	</ul>
	</nav>    
	);
};


const Content = ({htmlSections, pills}) => {

	return (
		<div  data-spy="scroll" data-target="#topnavbar" data-offset="0">
		 {htmlSections && htmlSections.map((htmlSection, i) => {
				return (
					<div id={pills[i]}
						dangerouslySetInnerHTML={{ __html: htmlSection }} />
				);
			}, pills)}
		</div>
	);
};

  return (
	<>
		<NavBar pills={pills}/>
		<Content htmlSections={htmlSections} pills={pills} />	
		
	</>
   );

};

export default Sample2;
