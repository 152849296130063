import React, {useEffect, useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import parse from 'html-react-parser';

import './MultiEditor.css';

const letters = ['A','B','C','D','E','F','G','H','I'];

const MultiEditor = ({question, dispatch, editMode}) => {
	
//	console.log("MultiEditor", question);

	const [id, setId]  = useState();
	const [questionText, setQuestionText]  = useState("");
	const [choices, setChoices] =  useState([]);
	const [answer, setAnswer] = useState([]);
	const [explain, setExplain] = useState("");
	const [showChoicesHTML, setShowChoicesHTML] = useState(false);
	const [showExplainHTML, setShowExplainHTML] = useState(false);
	const [showQuestionHTML, setShowQuestionHTML] = useState(false);
	
	useEffect(() => {
			if(question){ 	setId(question.id);
					setQuestionText(question.question);
					setChoices(question.choices);
					setAnswer(question.answer);
					setExplain(question.explain);
				
			}	
	},[]);


/////////////////////////
// 	METHODS


	const removeChoice = (event) => {
		const nextChoices = choices.filter((_, i) => { 
			if( i !== parseInt(event.target.id)) return true})
	//	console.log("removeChoice", nextChoices)
		setChoices(nextChoices);
	};	 
		
	const addChoice = () => {
	//	console.log(choices)
		if(choices === undefined || choices.length === 0)
			setChoices(['']);
		else setChoices([...choices, '']);
	}

	const updateChoices = (newChoice, index) => {
	  	const nextChoices = choices.map((c, i) => {
			if (i === index) return newChoice;	
				else  return c; 
		});
    		setChoices(nextChoices);
	}

	const HtmlQuestion = ({show}) => {
			return  (show) ? 	<div id='html-question'>
							<p>{questionText && parse(questionText)}</p>
						</div>
						: null	;			
	}

	const HtmlChoices = ({show}) => {
			const choicesExist = (choices && choices.length !== 0 && choices[0] !== '')? true : false;
			console.log(choices, choicesExist)
			return  (show) ? 	<div id='html-choices'>
							{choicesExist && choices.map((c, i) => {
								return(	<p><span>{letters[i]} :</span>
									<span>{parse(c)}</span></p>);
							})}
						</div>
						: null	;			
	}

	const HtmlExplain = ({show}) => {
			console.log(show, explain)
			return  (show) ? 	<div id="html-explain" className="my-3"><p>{explain && parse(explain)}</p></div>
						: null	;			
	}

	const quitHandler = () => {
		dispatch({type:'update-mode', mode:'questions'})
		dispatch({type:'update-current-question', question:null})
	}	
	
	const saveMCImageQuestion = () => {

		if(editMode)  dispatch({type:'edit', question: {id: id,
								type: 'multi',
								question: questionText,
								choices: choices,
								answer: answer,
								explain: explain }} );
		 else 	dispatch({type:'add',  question: {	id: id,
								type: 'multi',
								question: questionText,
								choices: choices,
								answer: answer,
								explain: explain }		
		} );		
	
		dispatch({type:'update-mode', mode:'questions'})
	}

	const toggleChoicesHTML = () => {
		if(showChoicesHTML) setShowChoicesHTML(false);
			else setShowChoicesHTML(true);
	}

	const toggleExplainHTML = () => {
		if(showExplainHTML) setShowExplainHTML(false);
			else setShowExplainHTML(true);
	}
	
	const toggleQuestionHTML = () => {
		if(showQuestionHTML) setShowQuestionHTML(false);
			else setShowQuestionHTML(true);
	}




////////////////////////
//	JSX

	return (

		  <Form>
			<Row><p className="fw-bold m-2">ID:{question && question.id}</p></Row>
			<Row>	

				<InputGroup>
					<InputGroup.Text>Question</InputGroup.Text>
						<Form.Control as="textarea" value={questionText} 
							      onChange={(event) => setQuestionText(event.currentTarget.value)} />
      					</InputGroup>
			 </Row>
			<Row>	<Col xs={2}><Button onClick={toggleQuestionHTML}>Show HTML </Button></Col>
				<Col><HtmlQuestion show={showQuestionHTML}/></Col>
			
			</Row>


				<Row> <p className="fw-bold m-2">Choices</p></Row>
				  { choices && choices.map((choice, index) => {
			
				return (

					<InputGroup key={"C" + index} className='m-2'>
						<InputGroup.Text className='fw-bold'>{letters[index]}</InputGroup.Text>
							<Form.Control size="lg" type="text" value={choices[index]} style={{fontSize:"16px"}}
								 onChange={(event) => updateChoices(event.currentTarget.value,index)}
								className='rounded'/>
							<Button onClick={removeChoice} id={index} variant='danger' className="rounded m-1 me-3 px-2 py-0"> X </Button>
      					</InputGroup>



	 			);
	 			})}
				<Row>	<Col xs={2} className='m-2'><Button onClick={addChoice} className="custom-btn ms-4 px-2 py-0"> Add Choice + </Button></Col>
					<Col xs={2} className='m-2'><Button onClick={toggleChoicesHTML} className="custom-btn ms-4 px-2 py-0"> Show HTML </Button></Col>
					<Col></Col>
				</Row>
				<Row><HtmlChoices show={showChoicesHTML} /></Row>



				<div className="d-flex flex-row">
		 		  <Form.Label sm="2" ><p className="fw-bold m-2">Answer:</p></Form.Label>
	 			  <Form.Control id="answer-input" size="lg" type="text" 
						value={answer} onChange={(event) => setAnswer(event.currentTarget.value)} /></div>

				 <p className="fw-bold mt-3">Explanation:</p>
				 <InputGroup className='mx-2 me-2'>        			
        				<Form.Control as="textarea" 
							onChange={(event) => setExplain(event.currentTarget.value)} value={explain}/>
						<InputGroup.Text className='me-3' onClick={toggleExplainHTML}>Show HTML</InputGroup.Text>
      				</InputGroup>



			
				  
				  <HtmlExplain show={showExplainHTML} />
			
				<Button variant="primary" className="m-1" onClick={saveMCImageQuestion}>Save</Button>
				<Button variant="primary" className="m-1" onClick={quitHandler}>Quit</Button>
 	 		    </Form>

	)
}

export default MultiEditor ;
