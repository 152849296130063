import {useEffect, React} from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

import MultiEditor from './MultiEditor.jsx';
import MultiImageEditor from './MultiImageEditor.jsx';
import MultiImagesEditor from './MultiImagesEditor.jsx';
import DragDropEditor from './DragDropEditor.jsx';

import './QuestionEditor.css';

const Editor = ({ question, dispatch, editMode, subject }) => {


	useEffect(() => {
			const stopUnload = (event) => {
				event.preventDefault();
				return (event.returnValue = '');
			};
			window.addEventListener('beforeunload', stopUnload, {capture: true});

	},[]);	


	
	//////////////////////////////////////////////////////////////////////////////////////////////
	//Main render
	/////////////////////////////////////////////////////////////////////////////////////////////

	console.log("Editor", question, subject);
	
	return (<div> 
			{(() => {
				
				if(!question || question === undefined) return ;
				console.log(question.type);
				switch(question.type){
					case 'multi' :
						return <MultiEditor question={question} dispatch={dispatch} editMode={editMode} />;
					case 'multi-image' :
						return <MultiImageEditor question={question} subject={subject} dispatch={dispatch} editMode={editMode}/>;
					case 'multi-images' :
						return <MultiImagesEditor question={question} dispatch={dispatch} editMode={editMode} subject={subject}/>;
					case 'drag' :
						return <DragDropEditor subject={subject} question={question} dispatch={dispatch} editMode={editMode}/>;
					default :
						return null;
				};
			     })()}			
		
		</div>);
			
				


	
	


}

export default Editor;
