import React, {useEffect, useState, useRef} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';

import EdittableDragDropQuestion from './EdittableDragDropQuestion.jsx';
import './DragDropEditor.css';

import parse from 'html-react-parser';

const letters = ['A','B','C','D','E','F','G','H','I'];

const DragDropEditor = ({subject, question, dispatch, editMode}) => {

	const [imagePath, setImagePath] = useState(null);
	const [drop, setDrop] = useState(null);
	const [initPosTargets, setInitPosTargets] = useState();
	const [initPosDrops, setInitPosDrops] = useState();
	const [explainText, setExplainText] = useState();
	const [lockTargets, setLockTargets] = useState(false);
	const [showQuestionModal, setShowQuestionModal] = useState(false);
	const [newDropModal, setNewDropModal] = useState(false);
	const [showHTML, setShowHTML] = useState(false);	
	const [selectedTargets, setSelectedTargets] =  useState([]); 
	const [edittedQuestion, setEdittedQuestion] = useState();
	const [answerMap, setAnswerMap] = useState(new Map());

	const boxWidthRef = useRef();
	const boxHeightRef = useRef();
	const fontRef = useRef();
	const ansRefs = useRef([]);
	const qModalRef = useRef();
	const dropModalInputRef = useRef();
	const colorPickerRef = useRef();
	const questionFontSizeRef = useRef();	

	const questionModalClose = () => setShowQuestionModal(false);
  	const questionModalShow = () => setShowQuestionModal(true);
	const dropModalClose = () => setNewDropModal(false);
  	const dropModalShow = () => setNewDropModal(true);

	useEffect(() =>{
			console.log(editMode);
			const newQuestion = {
						...edittedQuestion,
						id: (question.id)? question.id : 0 ,
						question: (question.question)? question.question : "",
						type: "drag",
						image : (question.image)? question.image: null,
						ansImage : (question.ansImage)? question.ansImage: null,
						answer : (question.answer)? question.answer : [],
						drops : (question.drops)? question.drops: null,
						targets : (question.targets)? question.targets: null,
						explain: (question.explain)? question.explain : null,
						textBoxDims : (question.textBoxDims)? question.textBoxDims : [120, 30],
						fontSize : (question.fontSize)? question.fontSize : null ,
						questionFontSize: (question.questionFontSize)? question.questionFontSize : 16
					};

			console.log(question.drops);
			if(question.drops && question.drops !== ""){
				const posDrops = question.drops.map((d) => [d.x, d.y]);
					setInitPosDrops(posDrops);
			}
			if(question.targets && question.targets !== ""){
				const posTargets = question.targets.map((t) => [t.x, t.y])
				setInitPosTargets(posTargets);
			}			
			setEdittedQuestion(newQuestion);
			console.log(newQuestion)

			document.getElementById('font-in').value = question.fontSize;		
			
			if(question.answer){
					 question.answer.map((l, i) => {
					 answerMap.set('T' + i, l);		
					}, );
			}
			
	
	},[]);
	
	useEffect(() => {
	       ansRefs.current = ansRefs.current.slice(0, question.drops);
	}, [question]);

/////////////////////////////////////////////////////////////////////////////////
//			 Methods
const addDrop = () => {
		console.log("addDrop", edittedQuestion.drops);
		let nextDrops = null;
		const numDrops = (edittedQuestion.drops)? edittedQuestion.drops.length : 0;
		if(numDrops != 0){
			const lastId = edittedQuestion.drops[edittedQuestion.drops.length - 1].id;
			const index = letters.indexOf(lastId);
			const nextId = letters[index+1];
			nextDrops = [ ...edittedQuestion.drops, { "id": nextId, "text": dropModalInputRef.current.value}];
		} else  nextDrops =  new Array({"id":"A", "text": dropModalInputRef.current.value });
		setEdittedQuestion({...edittedQuestion, drops: nextDrops});
		dropModalClose();
	};

	const addImage = async () => {
		console.log('addImage');
		let fileHandle;
		try{
			const options = { types: [{   description: "Images",  accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"]  }}]				
					};
			fileHandle = await window.showOpenFilePicker();
			setEdittedQuestion( { ...edittedQuestion, image :  fileHandle[0].name });
		}
		catch(error){
			console.log(error);
		 }
	};

	const addAnsImage = async () => {
		console.log('addAnsImage');
		let ansFileHandle;
		try{
			ansFileHandle = await window.showOpenFilePicker();
			setEdittedQuestion( { ...edittedQuestion, ansImage :  ansFileHandle[0].name });
		}
		catch(error){
			console.log(error);
		 }
	};

 
	const addTarget = () => {
		console.log("addTarget", edittedQuestion.targets);
		let nextTargets = null;
		if(edittedQuestion.targets && edittedQuestion.targets.length != 0 ){
			const lastId = edittedQuestion.targets[edittedQuestion.targets.length - 1].id;
			const nextId = "T" + (parseInt(lastId.split('').slice(1).join()) + 1);
			nextTargets = [ ...edittedQuestion.targets, { "id": nextId, "x": 50, "y": 50 }];
			nextTargets.forEach((t, i)=> t.id='T'+ i);
		} else nextTargets =  new Array({"id": "T0", "x": 50, "y": 50 });
	
		setEdittedQuestion({ ...edittedQuestion, targets: nextTargets});
	};
	
	const alignTargetsHoriz = () => {
		let nextTargets = [...edittedQuestion.targets];
		if(selectedTargets.length === 0) return;
		const firstId = selectedTargets[0];
		let setX;
		nextTargets.forEach((t)=>{
			if(t.id === firstId) setX = t.x; 
			if(selectedTargets.includes(t.id)){
				t.x = setX;	
			}
		}, firstId, setX);
		setEdittedQuestion({...edittedQuestion, targets: nextTargets});
	};

	const delHandler = (id) => {
		console.log("delHandler", id);
		const type = id[0];
		switch(type){
			case 'T':
				const nextTargets = edittedQuestion.targets.filter((t) => t.id !== id);
				nextTargets.forEach((t, i)=> t.id='T'+ i);
				setEdittedQuestion({...edittedQuestion, targets:nextTargets});
				break;
			default :				
				const tempDrops = edittedQuestion.drops.filter((d) => d.id !== id);
				const nextDrops = tempDrops.map((d, i)=>{d.id=letters[i];return d;});
				setEdittedQuestion({...edittedQuestion, drops:nextDrops});
				resetAnswer();
				break;
		}
	};
	
	const dropModalSaveHandler = (text) => {
		console.log("saveDropText", text, drop);
		
		const nextDrop = {...drop, text:text};
		const filteredDrops = edittedQuestion.drops.filter((d) =>  d.id !== drop.id, drop);
		const nextDrops = [...filteredDrops, nextDrop];
		nextDrops.sort((a,b) => {
			let ida = a.id;
			let idb = b.id;
			if (ida < idb) return -1;
			if (ida > idb) return 1;
			return 0;
		});
		console.log(nextDrops);
		setEdittedQuestion({...edittedQuestion, drops:nextDrops});
		dropModalClose();
		setDrop(null);
	};

	const dropModalCloseHandler = () => {
		setDrop(null);
		dropModalClose();
	};

	const editDropHandler = (id) => {
		console.log("editDropHandler")
		const drop = edittedQuestion.drops.filter((d) => d.id === id)[0];
		setDrop(drop);
		dropModalShow();
	};

	const handleClose = () => {
	
		edittedQuestion.drops && edittedQuestion.drops.map( (d, i) => { 
				d.x = initPosDrops[i][0];
				d.y = initPosDrops[i][1];  });
		edittedQuestion.targets && edittedQuestion.targets.map( (t, i) => { 	
				t.x = initPosTargets[i][0];
				t.y = initPosTargets[i][1];  });	
	};

	const handleLockTargets = () => {
		setLockTargets(l => !l);
	};

	const pickImageFolder = () => {
		console.log("pickImageFolder")
	};
	
	const quitHandler = () => {
		dispatch({type:'update-mode', mode:'questions'});
		dispatch({type:'update-current-question', question:null});
	};	

	const resetAnswer = () =>{
		const ans = document.getElementsByClassName('ans-in');
		console.log(ans);
		for (let i = 0; i < ans.length; i++) {
			ans[i].value=null;
		}
	};

	const saveHandler = () => {
		console.log("saveHandler", edittedQuestion, editMode);
		if(editMode) dispatch({type:'edit', question:edittedQuestion});
			else  dispatch({type:'add', question:edittedQuestion});
		dispatch({type:'update-mode', mode:'questions'});
	};
	
	const selectTarget = (index) => {
		console.log("selectTarget", selectedTargets);
		const id =  edittedQuestion.targets[index].id;
		const ele = ansRefs.current[index];
		if(!selectedTargets.includes(id)){
			ele.style.background =  "#cfe2ff";			
			setSelectedTargets([...selectedTargets, id] );
		} else { ele.style.background =  "";			
			setSelectedTargets(selectedTargets.filter((t)=> t!==id));
		}
		console.log(selectedTargets);
	};

	const toggleHTML = () => {
		if(showHTML) setShowHTML(false);
		else setShowHTML(true);
	};

	const updateAnswer = (e, target) => {
		console.log(e.target.value, target.id);
		const index = target.id.slice(1);
		console.log(index, edittedQuestion.answer);
		const newAnswer = (edittedQuestion.answer)? edittedQuestion.answer : Array(question.drops.length).fill('-')	;
		newAnswer[index] = e.target.value;
		console.log(newAnswer);
		setEdittedQuestion( {...edittedQuestion, answer: newAnswer});

		answerMap.set(target.id , e.target.value);
	};


	const updateBoxDims = () => {
		boxWidthRef.current.value = Math.max(boxWidthRef.current.value, 30);
		boxWidthRef.current.value = Math.min(boxWidthRef.current.value, 450);
		boxWidthRef.current.value = Math.max(boxWidthRef.current.value, 30);
		boxWidthRef.current.value = Math.min(boxWidthRef.current.value, 200);
		setEdittedQuestion({ ...edittedQuestion, textBoxDims: [parseInt(boxWidthRef.current.value), parseInt(boxHeightRef.current.value)]});
	};

	const updateCoords = (ref) => {
		const type = ref.attrs.id.split('').shift();
		switch(type){
			case 'T':
				edittedQuestion.targets.map((t) => {
					if(ref.attrs.id === t.id) {
						t.x = Math.round(ref.attrs.x*10) / 10;
						t.y = Math.round(ref.attrs.y*10) / 10;
					}
				});
				break;
			case 'D':
				edittedQuestion.drops.map((t) => {
					if(ref.attrs.id === t.id) {
						t.x = Math.round(ref.attrs.x*10) / 10;
						t.y = Math.round(ref.attrs.y*10) / 10;
					}
				});
				break;
			default:
		}
			
	};

	const updateFont = () => {
		if(fontRef.current.value > 30) fontRef.current.value = 30;
		if(fontRef.current.value < 5)  fontRef.current.value = 5;
		setEdittedQuestion( { ...edittedQuestion, fontSize : parseInt(fontRef.current.value) });
	};

	const updateQuestion = () => {
		
		setEdittedQuestion({  ...edittedQuestion, question : qModalRef.current.value }) ;
		questionModalClose();
	};

	const updateQuestionFontSize = () => {
		console.log(edittedQuestion.questionFontSize);
		setEdittedQuestion({  ...edittedQuestion, questionFontSize : parseInt(questionFontSizeRef.current.value) }) ;

	}

////////////////////////////////////////////////////////////////////////////////
//			JSX


///////////////////////
// Main Render

	return 		<div className="w-100">

			{/**********  Drop Modal  *****************************************************************/}
			<Modal show={newDropModal} onHide={dropModalClose}
				size='lg'>
        			<Modal.Header closeButton>
       		  			<Modal.Title>Add Drop</Modal.Title>
			        </Modal.Header>
	      			<Modal.Body>
					<Form>
						<Form.Label>Drop text :</Form.Label>
						<Form.Control type="text"  ref={dropModalInputRef} defaultValue={(drop)? drop.text: null}/>
						<Form.Control type="color" ref={colorPickerRef}  className='m-2' defaultValue="#9ef0ef" title="Choose color" />
					</Form>
				</Modal.Body>
        			<Modal.Footer>
	          			<Button onClick={dropModalCloseHandler}>Close</Button>
					{(drop)? <Button onClick={() => dropModalSaveHandler(dropModalInputRef.current.value)} >Save</Button>
							 : <Button onClick={addDrop}>Create</Button> }

			       	</Modal.Footer>
			</Modal>
			{/***********************************************************************************************/}
			{/************  Edit Question text Modal********************************************************/}
			<Modal show={showQuestionModal} onHide={questionModalClose}
				size='lg'>
        			<Modal.Header closeButton>
       		  			<Modal.Title> Question</Modal.Title>
			        </Modal.Header>
	      			<Modal.Body>
					<Form>
						<Form.Label >Question</Form.Label>
						<Form.Control as="textarea" rows={4} 
							      defaultValue={(edittedQuestion)? edittedQuestion.question : ""} ref={qModalRef}/>
						<Row><Col></Col>
						     <Col xs={3}><p id="font-label">Font Size:</p></Col>
						     <Col xs={2}><Form.Control type="number" id="question-font-size"
							  value={(edittedQuestion)? edittedQuestion.questionFontSize :  16} onChange={updateQuestionFontSize} 
							  ref={questionFontSizeRef}  />	</Col></Row>
					</Form>
				</Modal.Body>
        			<Modal.Footer>
	          			<Button variant="secondary" onClick={questionModalClose}>Close</Button>
					<Button variant="primary" onClick={updateQuestion}>Save Changes</Button>
			       	</Modal.Footer>
			</Modal>





	{/*******************************   MAIN CONTENT  *********************************************/}
			<EdittableDragDropQuestion subject={subject} question={edittedQuestion} lockTargets={lockTargets} delHandler={delHandler} editHandler={editDropHandler} 
				updateXY={updateCoords} openQuestionModal={questionModalShow} selectedTargets={selectedTargets}/>
		
			<Container className='controls w-40 ' >
		
				<Row>
				 <Col className='border shadow rounded'  xs={8}>
					<Row>
					  <Col xs={2}><button type="button" className='ctl-btn' onClick={addImage}>Add Image</button></Col>
					  <Col xs={2}><button type="button" className='ctl-btn' onClick={addAnsImage}>Add Ans. Image</button></Col>
					  <Col xs={2}><button type="button" className='ctl-btn' onClick={questionModalShow}>Edit Question</button></Col>
					  <Col xs={2}><button type="button" className='ctl-btn' onClick={addTarget}>Add Target</button></Col>
					</Row>		
					  
					<Row>
					
					  <Col xs={2}><button type="button" className='ctl-btn' onClick={dropModalShow}>Add Drop</button></Col>
					  <Col xs={2}><button type="button" className='ctl-btn' onClick={alignTargetsHoriz}>AlignTargets(H)</button></Col>
					  <Col><Form><Form.Group >
						    <Row><Col className='p-0 m-0'>
							     <p id="width-label">TextBox Width</p>
							     <p id="height-label">TextBox Height</p>
							     <p id="font-label">Font-size</p>
							</Col>
							 <Col className='p-0 m-1'><Form.Control type="number" id="width-in"
									value={(edittedQuestion)? edittedQuestion.textBoxDims[0] : 120}
									onChange={updateBoxDims} ref={boxWidthRef} />
						    		<Form.Control type="number" id="height-in" 
									value={(edittedQuestion)? edittedQuestion.textBoxDims[1] : 30} 
									onChange={updateBoxDims} ref={boxHeightRef}  />
								<Form.Control type="number" id="font-in" 
									defaultValue={(edittedQuestion)? edittedQuestion.fontSize : null} 
									onChange={updateFont} ref={fontRef}  />
							</Col></Row>
					       </Form.Group></Form>
					   </Col>
					 
					</Row>
					<Row className='mb-4'>				
					 
					  <Col xs={3}><Form.Check label='Lock Targets' type='switch' onChange={handleLockTargets}/></Col>
					  <Col ></Col>
					</Row>
			  </Col>
				  <Col xs={3} className='pt-2 m-2 border shadow rounded'>
					<Row><Form.Label>Answers</Form.Label></Row>
					     {(edittedQuestion && edittedQuestion.targets && edittedQuestion.targets.length != 0)?
						 edittedQuestion.targets.map((t, i) => 
									<Row key={t.id} ref={el => ansRefs.current[i] = el} onClick={()=>selectTarget(i)}>
									  
									  <Col sm={4}><input type="text" defaultValue={answerMap.get(t.id)} className='ans-in' 
											onChange={(e)=>updateAnswer(e,t)}
											onClick={(e)=>e.stopPropagation()} /></Col></Row> 
									) : null}
										<Button className='ans-but mb-2 mt-0' onClick={resetAnswer}>Reset</Button>
				  </Col>				 			
				</Row>	
				<Row><Col xs={2}><Form.Label className='mt-3 fw-bold'>Explanation:</Form.Label></Col></Row>
				<Row><Form.Control as="textarea" onChange={(event) => setExplainText(event.currentTarget.value)} value={explainText} style={{ height: '100px' }} /></Row>
				<Row><Col><Form.Check className='mt-3 fw-bold'  label='show HTML' type='switch' onChange={toggleHTML}/></Col></Row>
				<Row id="html" className="my-4 border border-primary rounded">{ showHTML && <Form.Label>{parse(explainText)}</Form.Label>}</Row>  
				<Row>						
					<Col xs={9}></Col>
					<Col ><Button className='footer-btn' variant="secondary" onClick={handleClose}>Close</Button></Col>
					<Col ><Button className='footer-btn'  onClick={saveHandler}>Save</Button></Col>
					<Col ><Button className='footer-btn' variant="danger" onClick={quitHandler}>Quit</Button></Col>
				</Row>
			</Container>


		</div>;
}

export default DragDropEditor ;
