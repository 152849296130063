import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism';

import mdstyles from './style.module.css';

const url = 'http://localhost:5000/';

const HTMLDisplay= ({subject,filename}) =>{

	console.log(subject, filename);

	useEffect(()=>{
		
		let data;
	
		const downloadMd   = async () =>{
  			console.log("downloading html subject:",subject," filename:", filename);	

			console.log(url + 'tutorialmd/' + subject + '/' + 'markdown/' +  filename);
			fetch(url + 'tutorialmd/' + subject + '/' +  filename)
	  				.then(function(response) {
				    return response.text();
				  }).then(function(data) {
					console.log(data); // this will be a string
					setMarkdown( data);
			  });

			
		};
		downloadMd();
	
	}, [subject, filename]);

	const [markdown, setMarkdown] = useState();

	useEffect(()=>{
		console.log(markdown);
	},[markdown]);

	return (
	<div className={mdstyles.mddisplay}>
	
			 <ReactMarkdown 	children={markdown} 
						remarkPlugins={[remarkGfm]}
						className={mdstyles.reactMarkDown}
						components={{
 							     code({node, inline, className, children, ...props}) {
    								    const match = /language-(\w+)/.exec(className || '');
						        	return !inline && match ? (
							          <SyntaxHighlighter
								            children={String(children).replace(/\n$/, '')}
								            style={dark}
								            language={match[1]}
								            PreTag="div"
								            {...props}
          							   />
        						) : (     <code className={className} {...props}>
								            {children}
          							  </code>
        							);
      						}
			}} 
			/>
		</div>

		);
}
export default HTMLDisplay;


