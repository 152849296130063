import {useState, useEffect, useReducer, useRef, React} from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Editor from './Editor.jsx';
import QuestionDisplay from './QuestionDisplay.jsx';
import ImportQuestions from './ImportQuestions.jsx';
import MergeQuestions from './MergeQuestions.jsx';
import AddQuestion from './AddQuestion.jsx';

import './EditorManager.css';


const EditorManager = () => {
	

	const [mode, setMode] = useState('edit');
		
	const questionsDir = useRef( require.context('../questions'));

	///////////////////
	//	State		: questions : current loaded question set
	//			  subject -> subject of loaded question
	//			  filename -> current loaded filename
	//			  mode -> tab/mode of broswer questions/edit/import/merge/preview see 'Content' JSXquestions -> loaded questions
	//			  currentQuestion -> question being editted/previewed
	//			  editMode -> true if currentQuestion exists and is being editted, false if new question not in question set
	
	const reducer =  (state, action) => {
		console.log("reducer called", action, typeof(action.type));
		

		if(action.type === 'add') {
			console.log("dispatch: add", action.question);
			return{
				...state, questions: [...(state.questions) ? state.questions : [], action.question]
			};
		}
		
	
	
		else if (action.type === 'delete') {
			const nextQuestions = state.questions.filter((q) => q.id !== action.id);
			return {
				...state, questions: nextQuestions
			}		
		}
		
		else if (action.type === 'edit') {
			
			console.log("dispatch: edit", action.question);
			const index = state.questions.findIndex((q) => { return q.id === action.question.id;});
			console.log(index);
			const newQuestions = [...state.questions];
			console.log(newQuestions);
			newQuestions[index] = action.question;
			
			return { ...state, questions: newQuestions};
		}
	

		else if (action.type === 'import') {
			return {
				...state,
				subject: action.subject,
				questions: action.questions

			};		
		}	

		else if (action.type === 'load') {
			console.log("load", state);
			return {
				...state,
				filename: action.filename,
				subject: action.subject
			};		
		}	

		else if(action.type === 'new-subject'){
			return {
				subject: action.subject,
				filename: action.filename,
				questions: null,
				currentQuestion: null,
				mode: 'questions',
				editMode: false
			};
		}

		else if(action.type === 'renumber'){
			console.log('renumber', state.questions);
			const newQuestions = state.questions.map((q, i) => {
				return { ...q, id:i};	
			});
			console.log("new questions:", newQuestions);
			return {
				...state, questions: newQuestions				
			};
		}


		else if (action.type === 'update-subject') {
			return {
				...state, subject: action.subject
			};		
		}
	
		else if (action.type === 'update-mode') {
			return {
				...state, mode: action.mode
			};		
		}
	
		else if(action.type === 'update-questions'){
			console.log('update-questions', action.questions);
			return {
				...state, questions: action.questions
			};
		}
				
		else if(action.type === 'update-edit-mode'){
			return {
				...state, editMode: action.editMode
			};
		}		

		else if (action.type === 'update-current-question') {
			console.log('update-current-question', action.question);
			return {
				...state, currentQuestion: action.question
			};		
		}


		else if (action.type === 'update-filename') {
			console.log('update-filename');
			return {
				...state, filename: action.filename
			}		
		}

	
		else throw Error('Unknown action.');
	}

	const [state, dispatch] = useReducer(reducer, {subject: null, questions: null, editMode:false, mode:'questions', currentQuestion: null, filename:null});
	 
	useEffect( () => {
		///loading
	 	const getQuestionSet = {};
	 	getQuestionSet.filename = state.filename;
		const jsonString = JSON.stringify(getQuestionSet);
		
		if( state.filename !== null && state.filename !==  undefined) {
		
			fetch('http://localhost:5000/get-questions/' + state.filename, {
				  method: 'GET',
				   headers:{'Content-Type': 'application/json',
					      "Access-Control-Allow-Origin": "*"}}
			).then(response => response.json())
			  .then(
				data => {	
						console.log("date returned from server request in Editor Manager");
					 	console.log(data.subject);
						console.log(data.questions);
						dispatch({type:'update-subject', subject:data.subject}); 
						dispatch({type:'update-questions', questions:data.questions});
				})
  			  .catch(error => {
    					console.log(error);
		  	});
		}
	}, [state.filename]);

	useEffect(() => {
		const navs = document.getElementsByClassName('nav-link');
	
		for (let nav of navs) {
			nav.style.background = '';
		}
		document.getElementById(state.mode).style.background = "#adb5bd"; 
	
	}, [state.mode]);
	
	const exportQuestions =  async (showConfirm) => {
		if(!state || state === undefined ) return;
	 	const exportData = {};
	 	exportData.subject = state.subject;
		exportData.filename = state.filename;
	 	exportData.questions = state.questions;
		const jsonString = JSON.stringify(exportData);

		try {
			const sendData = async () => {

			const res = await ( await fetch('http://localhost:5000/post', {
				  method: 'POST',
				  body: jsonString,
				  headers:{'Content-Type': 'application/json',
					     'Accept': 'application/json',
					      "Access-Control-Allow-Origin": "*"},
			  	  redirect: 'manual'
				})
			).json();
			console.log(res); 	 
			if(res.status === 'OK') showConfirm();
		};
		 sendData();
		} catch (error) {
			console.log(error);
		}
	 };

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//		JSX														//
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////	
	
	const Content = ({state}) => {
			console.log(state)

			 if(!state || !state.mode) return null;
			 switch(state.mode){
					case 'questions':						
						return  <QuestionDisplay 	subject={state.subject} questions={state.questions}
										filename={state.filename} dispatch={dispatch} 
										exportQuestions={exportQuestions} />;
					case 'edit':
					 	return  <Editor 		subject={state.subject} question={state.currentQuestion} dispatch={dispatch} 
										editMode={state.editMode} />;
					case 'add':
					 	return  <AddQuestion 		question={state.currentQuestion} dispatch={dispatch} 
										subject={state.subject}  />;
					case 'import':
						return <ImportQuestions 	dispatch={dispatch} />;
					case 'merge':
					 	return <MergeQuestions 		dispatch={dispatch} />;	
					default:
				}
	}

	return (
	<>
	 	<Navbar expand="lg" >
    
			          <Nav className="me-auto" >
        			   <Nav.Link id="questions" className="nav-bar" onClick={()=>dispatch({type:'update-mode', mode:'questions'})}>Questions</Nav.Link>
        			   <Nav.Link id="edit" className="nav-bar"  onClick={()=>dispatch({type:'update-mode', mode:'edit'})}>Edit</Nav.Link>
		        	   <Nav.Link id="add" className="nav-bar"  onClick={()=>dispatch({type:'update-mode', mode:'add'})}>Add</Nav.Link>           
		        	   <Nav.Link id="import" className="nav-bar"  onClick={()=>dispatch({type:'update-mode', mode:'import'})}>Import</Nav.Link>           
				   <Nav.Link id="merge" className="nav-bar"  onClick={()=>dispatch({type:'update-mode', mode:'merge'})}>Merge</Nav.Link>           
         			 </Nav>
    
      		
	    	</Navbar>			
	
		<Content state={state} />
	</>
		);


};

export default EditorManager;
