import react, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import './LoginPage.css';

const URL = 'http://localhost:5000/';

const LoginPage = () => {
		
	let navigate = useNavigate();

	const registerHandler = () => {
		navigate('/register')
	}

	return (
		<Container id='box'>
			<Row><Image src={URL + 'images/assets/title_icon.png'} id='logo'/></Row>
			<Row className='m-2'><Col xs={2}><Form.Label id='label'>Username:</Form.Label></Col>
		      	<Col className='ms-2'><Form.Control   type="password"  id="inputPassword5" /></Col>
			<Col xs={2}></Col>
			</Row>
			
			<Row className='m-2'><Col xs={2}><Form.Label id='label'>Password:</Form.Label></Col>
		      	<Col className='ms-2' ><Form.Control   type="password"  id="inputPassword5" /></Col>
			<Col xs={2}></Col>
			</Row>

			<Row>
				<Button onClick={registerHandler}>Register</Button>
				<Button>forgotten password</Button>
			</Row>
		</Container>
	);

}


export default LoginPage;
