import React , {useState, useRef, createRef, useLayoutEffect, useEffect } from 'react';
import {Stage, Layer, Image, Rect, Text, Group} from 'react-konva';
import useImage from 'use-image';
import './EdittableDragDropQuestion.css';

const URL = 'http://localhost:5000/' ;

const EdittableDragDropQuestion = ({subject, question, delHandler, editHandler,	lockTargets = false, updateXY, openQuestionModal, selectedTargets} ) => {

	const [editIcon] = useImage(URL + 'images/assets/edit.png');
	const [closeIcon] = useImage(URL + 'images/assets/close.png');	

	const dimsRef = useRef({'stageX':800,'stageY':600, 'winX':window.innerWidth, 'winY':window.innerHeight});
	const font = 'Calibri';
	const backgroundColor = "white";

	const layerRef = createRef();
	const targetRefs= useRef([]);
	const dropRefs= useRef([]);
	const targetPosRefs = useRef([]);
	
	const targetDragEnd = (event, textRef, targetRef, id) => {

		if(!lockTargets){
			const string = "x:" + (Math.round(event.target.attrs.x * 10) / 10) + " y:" + (Math.round(event.target.attrs.y* 10) /10) + " id:" + id;
			textRef.text(string);
		}
		updateXY(targetRef);
	};

	const dropDragStartHandler = (ref) => {
		ref.moveToTop();
	};
	
	const dropDragEndHandler = (event, ref) => {
//		updateXY(ref);
	};

	const limitDrag = (i) => {
		const ref = targetRefs.current[i]; 
		ref.x(Math.max(ref.attrs.x, 10));
		ref.x(Math.min(ref.attrs.x, dimsRef.current['stageX'] -  question.textBoxDims[0] - 20 ));
		ref.y(Math.max(ref.attrs.y, 10));
		ref.y(Math.min(ref.attrs.y, dimsRef.current['stageY'] -  question.textBoxDims[1] - 10));
	};

	const BackgroundImage = ({src}) => {
	
		const imageRef = useRef(null);
			
		const imageFolder = subject.toLowerCase().replace(/\s/g, '');
		const imageURL = URL + 'questions/images/' + imageFolder + '/' + src;
		console.log(imageURL);
		const [LoadedImage] = useImage(imageURL);
			
		return <Image className="image"  ref={imageRef} image={LoadedImage}  /> ;
	};


	return (
		
		<>
	 	{ (question && question.question) ?  <p fontFamily={font} fontSize={question.titleFontsize} className="ps-4">ID:{question.id} &ensp;	{question.question} </p> : null}

		<Stage className="stage" width={dimsRef.current['winX']} height={dimsRef.current['stageY']} stroke={"black"} strokeWidth={1} >
		
		<Layer className="layer">
			 { question && question.image && <BackgroundImage  src={question.image} /> }
			 <Rect x={dimsRef.current['stageX']} y={0} 
				width={dimsRef.current['winX'] - dimsRef.current['stageX']} height={dimsRef.current['stageY']}
			        fill="grey" shadowBlur={10} />
	 		<Image image={editIcon} width={30} height={30} x={dimsRef.current['stageX']-30} y={10}
  			       onClick={openQuestionModal} /> 
		</Layer>
		
		<Layer ref={layerRef}>  
				
				{ question && question.targets && question.targets.map((t, i) => {
					return (
						<Group 	key={i} id={t.id} ref={el => (targetRefs.current[i] = el)} 
						
								onDragEnd={(event) => targetDragEnd(event, targetPosRefs.current[i], targetRefs.current[i],  t.id)} 
								onDragmove={() => limitDrag(i)}
								draggable={!lockTargets}
								x={t.x} y={t.y} >
						<Rect   width={question.textBoxDims[0]} height={question.textBoxDims[1]} 
					      		stroke="black" strokeWidth={2} shadowBlur={5}
							fill={(selectedTargets.includes(t.id))? "#cfe2ff" : null} /> 
	                 { (!lockTargets) ?  <><Image image={closeIcon} width={20} height={20}  x={question.textBoxDims[0] - 10} y={-10}
									onClick={() => delHandler( t.id)} />  						
						<Text ref={el => (targetPosRefs.current[i] = el)}  text={"x:" + t.x + " y:" + t.y + "  id:" + t.id} y={-20}  /></>
								 : null}	
						</Group> ); }, selectedTargets)}
				
				{ question && question.drops  &&  question.drops.map((d, i) => {
						const numDrops = question.drops.length;
						const spacing = (dimsRef.current.stageY - (numDrops * question.textBoxDims[1])) / (numDrops+1);
						return (
							<Group 	key={i} id={d.id} ref={el => (dropRefs.current[i] = el)}
								x={dimsRef.current['winX'] - question.textBoxDims[0] - 50}
								y={spacing + ((question.textBoxDims[1] + spacing) * i)} >
								
									<Rect width={question.textBoxDims[0]} height={question.textBoxDims[1]} 
					      				stroke="black" strokeWidth={2} shadowBlur={5} fill={backgroundColor} cornerRadius={5}/> 	
										
						 			<Text text={d.text} fontSize={question.fontSize} x={question.textBoxDims[1]/2} y={question.textBoxDims[1] * 0.4}/>
									<Text text={d.id} x={-20} y={0} fontSize={question.fontSize}/>
					 				<Image image={editIcon} width={20} height={20} x={question.textBoxDims[0] - 10} y={10} onClick={() =>editHandler(d.id )}/>
								  	<Image image={closeIcon} width={20} height={20}  x={question.textBoxDims[0] - 10} y={-10} onClick={() =>delHandler(d.id )}/>
		
							</Group>);
					})				
        			}
		</Layer> 		
		</Stage>
		</>
	);

}



export default EdittableDragDropQuestion;
