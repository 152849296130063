import React , {useState, useRef, useEffect} from 'react';
import {Stage, Layer, Image, Rect, Text, Group} from 'react-konva';
import useImage from 'use-image';
import  './network.png';



const DragDropQuestion = ({	question, image, drops, targets,
				 textBoxWidth, textBoxHeight, setupMode = false } ) => {

	const [answers, setAnswers] = useState(Array(targets.length));

	useEffect(() => {
			console.log("Answers:", answers);	
	},[answers]);
	
	const width=800;
	const height=600;
	const borderX = 100;
	const borderY = 100;

	const font = 'Calibri';
	const fontsize = 20;
	const titleFontsize = 35;
	const backgroundColor = "white";

	const targetRefs= useRef([]);
	const targetTextRefs = useRef([]);
	const dropRefs= useRef([]);

	const removeAnswerByValue = (value) => {
		const nextAnswers = Array(targets.length);
		answers.map((answer, index) => {
			console.log(value, answer)
			if(answer !== value) nextAnswers[index]= answer;
		}, value);
		setAnswers(nextAnswers);
	}
	
	const resetChoicePosition = (value) => {
		dropRefs.current.map((drop, i) => {

		if( value === drop.children[1].attrs.text){
		 		console.log(value, i)
		 		drop.absolutePosition({x:drops[i].x  , y: drops[i].y});
		}	
		 });
	}
	
	const targetDragHandler = (event, ref, id) => {

		if(setupMode){
			const string = "x:" + event.target.attrs.x + " y:" + event.target.attrs.y + " id:" + id;
			ref.text(string)
		}
	}

	const dropDragStartHandler = (ref) => {

		ref.moveToTop();
		removeAnswerByValue(ref.children[1].attrs.text);
	}
	
	const dropDragEndHandler = (event, ref) => {
		console.log(event)
		const drop = event.target;
		const x = event.evt.clientX ;
		const y = event.evt.clientY ;
		console.log(x,y)

		let hit = null;
		targetRefs.current.map((target, index) =>{
			console.log(target)
			const rect_x = target.attrs.x;
			const rect_y = target.attrs.y;
			const width =  target.children[0].attrs.width;	
			const height = target.children[0].attrs.height;
			
			console.log(rect_x, rect_y, width, height)
			if((x > rect_x && x < (rect_x + width))
				&& ((y > rect_y && y < (rect_y + height )))){
				hit = index+1;		
			}
				
		}, hit);
		
		console.log("hit:", hit)

		if(hit){
			if(answers[hit-1]) resetChoicePosition(answers[hit-1])			

			const targetX = targetRefs.current[hit-1].attrs.x;
			const targetY = targetRefs.current[hit-1].attrs.y;
			drop.absolutePosition({x: targetX, y: targetY});
		
			const newAnswers = Array.from(answers);
			newAnswers[hit-1] =  drop.children[1].attrs.text;
			setAnswers(newAnswers);

		}
		
	}

	const BackgroundImage = ({src, question,  height}) => {

		const images = require.context('./images')
		const image = images(`${src}`)
		const [LoadedImage] = useImage(image);

		return 	<Layer>
				<Image image={LoadedImage} height={height} width={width} 
					x={100} y={100}	/>
				<Text text={question} fontFamily={font} fontSize={titleFontsize} 
					width={width} height={height} y={20} align='center' />
        		</Layer>
	}

	return (
		<Stage width={width} height={height} >
		<BackgroundImage src={image} question={question} height={height}/>
		 <Layer> 
				{targets.map((target, i) => {
					return (
					<Group 	key={i} ref={el => (targetRefs.current[i] = el)}
						onDragEnd={(event) => targetDragHandler(event, targetTextRefs.current[i], i)} draggable={setupMode}
						x={target.x} y={target.y} >
						<Rect   width={textBoxWidth} height={textBoxHeight} 
					      		stroke="black" strokeWidth={2} shadowBlur={5} /> 
				{(setupMode) ? <Text ref={el => (targetTextRefs.current[i] = el)}  text={"x:" +  " y:" } 
							y={-20}  /> : null } 
					</Group> )
					})}
			
				{	drops.map((drop, i) => {
						return (
							<Group 	key={i}	 ref={el => (dropRefs.current[i] = el)}
								x={drop.x} y={drop.y} 
								draggable onDragStart={() => dropDragStartHandler(dropRefs.current[i])}
									  onDragEnd={(event) => dropDragEndHandler(event, dropRefs.current[i])} >
								<Rect 	width={textBoxWidth} height={textBoxHeight} 
					      				stroke="black" strokeWidth={2} shadowBlur={5} fill={backgroundColor} cornerRadius={5}/> 
								<Text  text={drop.text}	
									x={10} y={10} 
									fontFamily={font} fontSize={fontsize}/>  
							</Group>)
					})}				
        		</Layer> 		
		</Stage>



	);

}



export default DragDropQuestion;
