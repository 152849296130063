
import {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Menu from './Menu.jsx';	
import './App.css';
import Player from './player/Player.jsx';

const imagesURL = 'https://194.164.120.140:5000/images';

function App() {


/////////////////////////////////////////////////////////////////////////////////////////////////////
// JSX
	

	return (
			
	    <div className="App">
		<Container fluid>
			<marquee direction="left">	<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
							Site is under Construction
							<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
			</marquee>
			<Row className="App-header">
        			<div id="banner">
                			<img id="icon" src={imagesURL + '/assets/title_icon.png'} alt="logo" />
				</div>

       			 </Row>
        	
            		 <Row>
				<Menu />


			</Row>		
			
	
		</Container>
     </div>

  );
}

export default App;
