import React, {useState, useEffect, useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';


import './MultiImagesEditor.css';

import parse from 'html-react-parser';

const letters = ['A','B','C','D','E','F','G','H','I'];
const url = 'http://localhost:5000';

const MultiImagesEditor = ({question, subject, dispatch, editMode}) => {


	const [answer, setAnswer] = useState("");
	const [choices, setChoices] =  useState([]);
	const [dirHandle, setDirHandle] = useState();
	const [image, setImage]  = useState("");
	const [explain, setExplain] = useState("");
	const [id, setId]  = useState();
	const [questionText, setQuestionText]  = useState("");
	const [showHTML, setShowHTML] = useState(false);

	const dirLabel = useRef();

	useEffect(() => {
		if(question){ 	setId(question.id);
				setQuestionText(question.question);
				setImage(question.image);
				setChoices(question.choices);
				setAnswer(question.answer);
				setExplain(question.explain);
		}
	},[]);

	const toggleHTML = () => {
		if(showHTML) setShowHTML(false);
			else setShowHTML(true);
	};
		
	const removeChoice = (event) => {
		const nextChoices = choices.filter((_, i) => { 
			if( i !== parseInt(event.target.id)) return true})
			setChoices(nextChoices);
	};	 
		
	const addChoice = () => {
		if(choices === undefined || choices.length === 0)
			setChoices([""]);
		else setChoices([...choices, ""]);
	};

	const addChoiceImage = async (event, index) => {
		const pickerOpts = {
  			types: [
    				{ description: "Images",
				  accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"]}
    				}
  			]  	
		};
		try {
			const [fileHandle] = await window.showOpenFilePicker(pickerOpts);
			//setDirHandle(fileHandle.name);
			const copyChoices = [...choices]; 
			if(!dirHandle) {
				console.log("no dir selected");
				return;
			}
			copyChoices[index] =  fileHandle.name;
			setChoices(copyChoices);
		} catch (error){
			console.log(error);
		}
	};

	const changeFolder = async () => {
		
		try{
			const pickerOpts = {
  				mode: "read",
				startIn: dirHandle
			};

			const handle = await window.showDirectoryPicker(pickerOpts);
			setDirHandle(handle);
			console.log(dirLabel.current);
			dirLabel.current.innerText = "Dir: .../" + handle.name + "/";
		} catch(error) {
			console.log(error);
		}
	};

	const quitHandler = () => {
		dispatch({type:'update-mode', mode:'questions'});
		dispatch({type:'update-current-question', question:null});
	};	
	
	const updateChoices = (newChoice, index) => {
	  	const nextChoices = choices.map((c, i) => {
			if (i === index) return newChoice;	
				else  return c; 
		});
    		setChoices(nextChoices);
	};

	const saveMCImageQuestion = () => {
	//	console.log(id, choices, editMode)
		if(editMode)  dispatch({type:'edit', question: {id: id,
								type: 'multi-images',
								question: questionText,
								choices: choices,
								answer: answer,
								explain: explain }} );
		 else 	dispatch({type:'add',  question: {	id: id,
								type: 'multi-images',
								question: questionText,
								choices: choices,
								answer: answer,
								explain: explain }		
		} );		
		dispatch({type:'update-mode', mode:'questions'});
	};

	const HtmlExplain = ({showHTML}) => {
		if( !showHTML || showHTML === undefined ) return;
		return  (showHTML) ? 	<>
					<p>{question.question}</p>
					{question.choices.map((c, i) => {
						<p><span>{letters[i]} :</span>
						<span>{parse(c)}</span></p>
					})}
					<div id="html" className="my-3"><p>{parse(explain)}</p></div>
					</>
					: null				
	};

	let folderName =  subject.toLowerCase().replace(/\s/g, '');
//	console.log(choices)
	return (

			  <Form>
				  <Row><Form.Label sm="2" ><p className="fw-bold m-2">ID:{question && question.id}</p></Form.Label></Row>
				  <Row><Form.Label sm="2" ><p className="fw-bold m-2">Question:</p></Form.Label></Row>
				  <Form.Control size="lg" type="text" value={questionText} onChange={(event) => setQuestionText(event.currentTarget.value)}/>
				  <Row>
				
					<Col xs={8}><p className="fw-bold m-2">Dir:{'  express/questions/images/' + folderName + '/'}</p></Col>
					<Col></Col>
				  </Row>
				<Row>
					<Col xs={1}><Form.Label className="m-2 fw-bold" sm="2">Choices</Form.Label></Col>
				</Row>
				  { choices && choices.map((choice, index) => {
				return (
					<div className="d-flex flex-row mb-2" key={"C" + index}> 
	 					<Form.Label className="m-2" sm="2"><b>{letters[index]}</b></Form.Label>
						<Row>
							{ (choice !== "") && <img src={url + '/questions/images/'+ folderName + '/'+  choice}  className='im' />}
						</Row>

						<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          						<Tooltip {...props}>Select Image</Tooltip>)} placement="right" >
							<Button onClick={(e)=>addChoiceImage(e,index)} className='sel-im'><i class="bi bi-file-image"></i></Button>
						</OverlayTrigger>

						<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          						<Tooltip {...props}>Delete Choice   </Tooltip>)} placement="right" >
							<Button onClick={removeChoice} id={index} variant="danger"
								 className="rem-ans m-1 px-2 py-0"> X </Button>
						</OverlayTrigger>
	 				</div>
	 			);
	 			})}
				<Row>
					<Col xs={2}>
						<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          						<Tooltip {...props}>Add Choice   </Tooltip>)} placement="right" >
							<Button onClick={addChoice} className="ms-4 px-2 py-0"> + </Button>
						</OverlayTrigger>
					</Col>
					<Col></Col>
				</Row>
				<div className="d-flex flex-row">
		 		  <Form.Label sm="2" ><p className="fw-bold m-2">Answer:</p></Form.Label>
	 			  <Form.Control id="answer-input" size="lg" type="text" value={answer} onChange={(event) => setAnswer(event.currentTarget.value)} /></div>
				  <Form.Label ><p className="fw-bold mt-3">Explanation:</p></Form.Label>
				  <Form.Control as="textarea"  onChange={(event) => setExplain(event.currentTarget.value)} value={explain} className="" style={{ height: '100px' }}  />
				  <Button onClick={toggleHTML}	variant="secondary" className="m-1">Show HTML</Button>
				  <HtmlExplain showHTML={showHTML} />
			
				<Button variant="primary" className="m-1" onClick={saveMCImageQuestion}>Save</Button>
				<Button variant="danger" className="m-1" onClick={quitHandler}>Quit</Button>

 	 		    </Form>
	);


}

export default MultiImagesEditor ;




