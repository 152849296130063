import React, {useState, useEffect, useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import parse from 'html-react-parser';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './MultiImageEditor.css';

const letters = ['A','B','C','D','E','F','G','H','I'];
const URL = 'http://localhost:5000/';

const MultiImageEditor = ({question, subject, dispatch, editMode}) => {

	const [id, setId]  = useState();
	const [questionText, setQuestionText]  = useState("");
	const [image, setImage]  = useState();
	const [imageText, setImageText]  = useState("");
	const [ansImageText, setAnsImageText]  = useState("");
	const [choices, setChoices] =  useState([]);
	const [answer, setAnswer] = useState("");
	const [explain, setExplain] = useState("");
	const [lockDims, setLockDims] = useState(true);
	const [showChoicesHTML, setShowChoicesHTML] = useState(false);
	const [showExplainHTML, setShowExplainHTML] = useState(false);
	const [showQuestionHTML, setShowQuestionHTML] = useState(false);
	const [size, setSize] = useState();

	const sizeXRef = useRef();
	const sizeYRef = useRef();
	const imageRef = useRef();

	useEffect(() => {
		if(question){ 	setId(question.id);
				setQuestionText(question.question);
				setImageText(question.image);
				setChoices(question.choices);
				setAnswer(question.answer);
				setExplain(question.explain);
				setSize(question.size);
		}
	
	},[]);

	const removeChoice = (event) => {
		const nextChoices = choices.filter((_, i) => { 
			if(i !== parseInt(event.target.id)) return true});
		console.log("removeChoice", nextChoices);
		setChoices(nextChoices);
	};	 
		
	const addChoice = () => {
		if(choices === undefined || choices.length === 0)
			setChoices(Array([""]));
		else setChoices([...choices, ""]);
	};
	
	const addImage = async () => {
		let fileHandle;
		try{
			fileHandle = await window.showOpenFilePicker();
			setImageText(fileHandle[0].name);
		}
		catch(error){
			console.log(error);
		}		 
	};

	const addAnsImage = async () => {
		let fileHandle;
		try{
			fileHandle = await window.showOpenFilePicker();
			setAnsImageText(fileHandle[0].name);
		}
		catch(error){
			console.log(error);
		}		 
	};

	const quitHandler = () => {
		dispatch({type:'update-mode', mode:'questions'});
		dispatch({type:'update-current-question', question:null});
	};
	
	const updateChoices = (newChoice, index) => {
	  	const nextChoices = choices.map((c, i) => {
			if (i === index) return newChoice;	
				else  return c; 
		});
    		setChoices(nextChoices);
	};

	const saveMCImageQuestion = () => {
		console.log(id, choices, editMode);
		if(editMode)  dispatch({type:'edit', question: {id: id,
								type: 'multi-image',
								question: questionText,
								image: imageText,
								ansImage: ansImageText,
								size:size,
								choices: choices,
								answer: answer,
								explain: explain }} );
		 else 	dispatch({type:'add',  question: {	id: id,
								type: 'multi-image',
								question: questionText,
								image: imageText,
								ansImage: ansImageText,
								size: size,
								choices: choices,
								answer: answer,
								explain: explain }		
		} );		
		dispatch({type:'update-mode', mode:'questions'});
	};

	const HtmlQuestion = ({show}) => {
			return  (show) ? 	<div id='html-question'>
							<p>{questionText && parse(questionText)}</p>
						</div>
						: null	;			
	};

	const HtmlChoices = ({show}) => {
			const choicesExist = (choices && choices.length !== 0)? true : false;
			return  (show) ? 	<div id='html-choices'>
							{choicesExist && choices.map((c, i) => {
							
								return(	(c[0] !== '')? <p><span>{letters[i]} :</span>
									<span>{parse(c)}</span></p> : null);
							})}
						</div>
						: null	;			
	};

	const HtmlExplain = ({show}) => {
		
			return  (show) ? 	<div id="html-explain" className="my-3"><p>{explain && parse(explain)}</p></div>
						: null	;			
	};

	const imageLoadHandler = ({target:img}) => {

		setSize( {x:img.naturalWidth, y:img.naturalHeight});
		img.style.width = img.naturalWidth;
		img.style.height = img.naturalHeight;
	};

	const toggleChoicesHTML = () => {
		if(showChoicesHTML) setShowChoicesHTML(false);
			else setShowChoicesHTML(true);
	};

	const toggleExplainHTML = () => {
		if(showExplainHTML) setShowExplainHTML(false);
			else setShowExplainHTML(true);
	};
	
	const toggleQuestionHTML = () => {
		if(showQuestionHTML) setShowQuestionHTML(false);
			else setShowQuestionHTML(true);
	};

	const toggleLock = () =>{
		setLockDims(!lockDims);
	};

	const updateSizeX = () => {
		if(!size) return;
		if(lockDims){
			const ratio = size.y/size.x;
			setSize({x:sizeXRef.current.value, y:Math.round(sizeXRef.current.value * ratio)});
			imageRef.current.width = Math.round(sizeXRef.current.value);
			imageRef.current.height = Math.round(sizeXRef.current.value * ratio);		
		} else {
			setSize({...size, x:sizeXRef.current.value});
			imageRef.current.width = sizeXRef.current.value;
		}
	};

	const updateSizeY = () => {
		if(!size) return;
		if(lockDims){
			const ratio = size.x/size.y;
			setSize({x:Math.round(sizeYRef.current.value * ratio), y:sizeYRef.current.value});
			imageRef.current.width = Math.round(sizeYRef.current.value * ratio);
			imageRef.current.height = Math.round(sizeYRef.current.value);		
		} else {
			setSize({...size, y:sizeYRef.current.value});
			imageRef.current.height = sizeYRef.current.value;
		}



	};	

	const updateScale = () => {
		if(lockDims){
			
		} else {

		}
	};	



	let folderName =  subject.toLowerCase().replace(/\s/g, '');


////////////////////////
//	JSX

	return (

		  <Form>
			<Row><p className="fw-bold m-2">ID:{question && question.id}</p></Row>
			<Row>	

				<InputGroup >
					<InputGroup.Text id='question-label'>Question</InputGroup.Text>
						<Form.Control as="textarea" value={questionText} id='question-box'
							      onChange={(event) => setQuestionText(event.currentTarget.value)} />
      					</InputGroup>
			 </Row>
			<Row>
				<Col></Col>
				<Col xs={2}><Button onClick={toggleQuestionHTML}>Show HTML </Button></Col>
			</Row>	
			<Row>
				<Col><HtmlQuestion show={showQuestionHTML}/></Col>
			</Row>

			<Row>
				<Col xs={2}><p className="fw-bold m-2">Image:   {imageText}</p></Col>
				<Col ><Button className='float-start' onClick={addImage}>Add Image</Button></Col>
				<Col ><Button className='float-start' onClick={addAnsImage}>Add Ans.Image</Button></Col>
				<Col><p className='dims-label'>Width :</p>
				     <p className='dims-label'>Height :</p>	</Col>
				<Col xs={1} id='input-col'>
					<Form.Control type="number" id="sizeX" 
							value={size && size.x} onChange={updateSizeX} ref={sizeXRef}  />
				
					<Form.Control type="number" id="sizeY"  
						value={size && size.y} onChange={updateSizeY} ref={sizeYRef}  />
				</Col>
				<Col xs={1}> 	
					<Button onClick={toggleLock} value={lockDims} className="rounded m-1 me-3 px-2 py-0" id='lock-but'>
								{(lockDims) ?<i class="bi bi-lock"></i> :  <i class="bi bi-unlock"></i>}
					</Button>
				</Col>
				<Col></Col>
			</Row>
			  {imageText &&  <Row >
					<Col><img src={URL + 'questions/images/' + folderName + '/' + imageText} onLoad={imageLoadHandler} ref={imageRef}/></Col>
				     </Row> }
			

				<Row> <p className="fw-bold m-2">Choices</p></Row>
				  { choices && choices.map((choice, index) => {
			
				return (

					<InputGroup key={"C" + index} className='m-2'>
						<InputGroup.Text className='fw-bold'>{letters[index]}</InputGroup.Text>
							<Form.Control size="lg" type="text" value={choices[index]} style={{fontSize:"16px"}}
								 onChange={(event) => updateChoices(event.currentTarget.value,index)}
								className='rounded'/>
						
      					</InputGroup>



	 			);
	 			})}
				<Row>	<Col xs={2} className='m-2'><Button onClick={addChoice} className="custom-btn ms-4 px-2 py-0"> Add Choice + </Button></Col>
					<Col xs={2} className='m-2'><Button onClick={toggleChoicesHTML} className="custom-btn ms-4 px-2 py-0"> Show HTML </Button></Col>
					<Col></Col>
				</Row>
				<Row><HtmlChoices show={showChoicesHTML} /></Row>

				<div className="d-flex flex-row">
		 		 <p className="fw-bold m-2">Answer:</p>
	 			  <Form.Control id="answer-input" size="lg" type="text" 
						value={answer} onChange={(event) => setAnswer(event.currentTarget.value)} /></div>

				 <p className="fw-bold mt-3">Explanation:</p>
				 <InputGroup className='mx-2 me-2'>        			
        				<Form.Control as="textarea" id='explain-input'
							onChange={(event) => setExplain(event.currentTarget.value)} value={explain}/>
						<InputGroup.Text id='explain-but' className='me-3' onClick={toggleExplainHTML}>Show HTML</InputGroup.Text>
      				</InputGroup>



			
				  
				  <HtmlExplain show={showExplainHTML} />
			
				<Button variant="primary" className="m-1" onClick={saveMCImageQuestion}>Save</Button>
				<Button variant="primary" className="m-1" onClick={quitHandler}>Quit</Button>
 	 		    </Form>

	)

}

export default MultiImageEditor ;
