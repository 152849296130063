import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Menu from '../Menu.jsx';	
import Player from '../player/Player.jsx';

const imagesURL = 'http://localhost:5000/images';

const PlayerPage = () => {

	const [newSubject, setNewSubject] = useState();
	const params  = useParams();

	useEffect(()=>{	
		console.log(params.subject);
 		setNewSubject(params.subject);
	}, [params]);

	return (
			
		<div className="PlayerPage">
			<Container fluid>
				<marquee direction="left">	<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
									Site is under Construction
								<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
				</marquee>
			<Row className="App-header">
        			<div id="banner">
                			<img id="icon" src={imagesURL + '/assets/title_icon.png'} alt="logo" />
				</div>
       			 </Row>
        	
            		 <Row>
				<Menu />
			</Row>	
			<Row><Player newSubject={newSubject} /></Row>	
		</Container>
     	</div>
	);	
}
export default PlayerPage;
