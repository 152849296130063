import { useNavigate  } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Menu.css';

const Menu = () =>{

	
	const navigate = useNavigate();

	const openPlayer = (subject) =>{
		navigate('/player/' + subject);
	};

	const openTutorial = (subject)=>{
		navigate('/tutorial/' + subject);
	};

	return (

	<Navbar expand="lg" className="bg-body-tertiary">
      		<Container>
        		<Navbar.Brand href="home">Home</Navbar.Brand>
        		<Navbar.Toggle aria-controls="basic-navbar-nav" />
        		<Navbar.Collapse id="basic-navbar-nav">
          		<Nav className="me-auto">
            <NavDropdown title="Questions" id="basic-nav-dropdown" onSelect={(selectedKey) => openPlayer(selectedKey)}>
        		  <NavDropdown.Item eventKey={"networkingbasics"}>Networking Basics</NavDropdown.Item>
          		  <NavDropdown.Item eventKey={"switchingbasics"}>Switching Basics</NavDropdown.Item>
          		  <NavDropdown.Item eventKey={"vlans"}>VLANs</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipv4routing"}>IPv4 Routing</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ospfrouting"}>OSPF Routing</NavDropdown.Item>
        		  <NavDropdown.Item eventKey={"advswitching"}>Advanced Switching</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipv6basics"}>IPv6 Basics</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipv6advanced"}>IPv6 Advanced</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"securitybasics"}>Security Basics</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"accesscontrollists"}>Access Control Lists</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"wirelessnetworks"}>Wireless Networks</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipservices"}>IP Services</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"networkarchitecture"}>Network Architecture</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"aetworkautomation"}>Network Automation</NavDropdown.Item>
            </NavDropdown>
	    <NavDropdown title="Tutorials" id="basic-nav-dropdown" onSelect={(selectedKey) => openTutorial(selectedKey)}>
	       		  <NavDropdown.Item eventKey={"networkingbasics"}>Networking Basics</NavDropdown.Item>
			<NavDropdown.Item eventKey={"switchingbasics"}>Switching Basics</NavDropdown.Item>
          		  <NavDropdown.Item eventKey={"vlans"}>VLANs</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipv4routing"}>IPv4 Routing</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ospfrouting"}>OSPF Routing</NavDropdown.Item>
        		  <NavDropdown.Item eventKey={"advswitching"}>Advanced Switching</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipv6basics"}>IPv6 Basics</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipv6advanced"}>IPv6 Advanced</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"securitybasics"}>Security Basics</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"accesscontrollists"}>Access Control Lists</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"wirelessnetworks"}>Wireless Networks</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"ipservices"}>IP Services</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"networkarchitecture"}>Network Architecture</NavDropdown.Item>
			  <NavDropdown.Item eventKey={"aetworkautomation"}>Network Automation</NavDropdown.Item>
            </NavDropdown>
	    <Nav.Link href="/about">About</Nav.Link>
          </Nav>
	
	<Form inline>
        <Row>
          <Col xs="auto">
            <Form.Control type="text" placeholder="Search" className="search-box m-0" />  </Col>      
              <Col xs="auto" className='m-0'><Button className='menu-btn' type="submit"><i className="bi bi-search p-0"></i></Button>
          </Col>
        </Row>
      </Form>



        </Navbar.Collapse>
      </Container>
    </Navbar>		
	
	);

}


export default Menu;

