import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Menu from '../Menu.jsx';	

import './HomePage.css';

const imagesURL = 'https://194.164.120.140:5000/images';

const HomePage = () => {
	return (
			
		<div className="App">
			<Container fluid>
				<marquee direction="left">
				  <img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
				  Site is under Construction
							
				</marquee>
			<Row className="App-header">
        			<div id="banner">
                	
				</div>
       			 </Row>
        	
            		 <Row>
				<Menu />
			</Row>		
		<h2 className='home-title'>
			NetworkQuestions.org
		</h2>	
		<p className='home-text'>
			Welcome to NetworkQuestions.org a resource to help you get qualified in networking.
		</p>
		
		<ul className='home-list'>
		<li className='home-list-item'>
			<span className='home-text'>
				Register to save your results in questions and progress in tutorials , map it to CCNA objectives.
			</span>
		</li>
		<li className='home-list-item'>
		<span className='home-text'>	
			Website is under development at present, there are questions in all of the sections. 
		 </span>
		</li>
		<li className='home-list-item'>
			<span className='home-text'> 	More to come in tutorials and additional resources.</span>
		</li>
		</ul>

		</Container>
     	</div>
	);	
}
export default HomePage;
