import {useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import MultipleChoice from'./MultipleChoice.jsx';
import MultiChoiceImage from'./MultiChoiceImage.jsx';
import MultiChoiceImages from'./MultiChoiceImages.jsx';
import DragDropQuestion from './DragDropQuestion.jsx';

function QuestionCarousel({ questionNumber, id, question, subject, dispatch , answers, isLast}){

	const subjectInv = require('../subject_inv.json');

	useEffect(() => {
		
		if(!subject) return; 		

		const filename = subjectInv[subject];
		console.log(filename)

		fetch('http://localhost:5000/get-questions/' + filename, {
			  method: 'GET',
			   headers:{'Content-Type': 'application/json',
				      "Access-Control-Allow-Origin": "*"}}
		).then(response => response.json())
		  .then(
			data => { 
				dispatch({type:'update_questions', questions:data.questions});
				dispatch({type:'next'});
			})
  		  .catch(error => {
    				console.log(error);
		  });

	}, [subject]);

	const nextHandler = () => {
		dispatch({type:'next'});
	};

	const backHandler = () => {
		dispatch({type:'back'});
	};


	//static Buttons component 
	const Buttons = () => { 
		return(	
			<>
			<Row className="btn-row">
			  <ButtonGroup  >
      				<Button className="btn" variant="secondary" onClick={backHandler} disabled={questionNumber===0}>Back</Button>
      				{(!isLast) ?<Button id="next" className="btn" variant="secondary" onClick={nextHandler} disabled={isLast}>Next</Button >: null }
				{(isLast) ? <Button id="finish" variant="secondary" onClick={nextHandler}>Finish</Button> : null }   
      			  </ButtonGroup>
			</Row>
			</>
			);
	};
	
	if(!question) return null;
	if(question.type === 'multi') return (<div className='root-questions'> 
								<MultipleChoice 
									questionNumber={questionNumber}
									question={question.question}
									id={question.id}
									choices={question.choices}
									numAnswers = {question.answer.length}
									currentAnswer = {answers.get(question.id)}
									dispatch={dispatch} />
								<Buttons />
						</div> );

	if(question.type === 'multi-image') return (<div><MultiChoiceImage 
									questionNumber={questionNumber}
									subject={subject}
									question={question.question}
									id={question.id}
									image={question.image}
									size={question.size}
									choices={question.choices}
									numAnswers = {question.answer.length}
									currentAnswer = {answers.get(question.id)}
									dispatch = {dispatch}	/>
								<Buttons />
						</div> );
	if(question.type === 'multi-images') return (<div><MultiChoiceImages 
									questionNumber={questionNumber}
									subject={subject}
									question={question.question}
									id={question.id}
									image={question.image}
									choices={question.choices}
									numAnswers = {question.answer.length}
									currentAnswer = {answers.get(question.id)}
									dispatch = {dispatch}	/>
								<Buttons />
							</div>);
			
	if(question.type === 'drag') return (<div><DragDropQuestion 
									questionNumber={questionNumber}
									subject={subject}
									question={question}
									id={question.id}
									currentAnswer = {answers.get(question.id)}
									dispatch = {dispatch} />
								<Buttons />
						</div>);
	
	
}

export default QuestionCarousel;
