import {React, useRef, useEffect, useState, useCallback} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Editor from './Editor.jsx';

const AddQuestion = ({dispatch, question, subject}) => {

	const [subjectList, setSubjectList] = useState();
	const questionsDir = useRef( require.context('..'));
	const subjectDropDown = useRef();
	const typeDropDown = useRef();

	useEffect(() =>{
		const subjectInv = questionsDir.current('./subject_inv.json');
		setSubjectList(subjectInv);	
	}, []);
	
	const newQuestionHandler = () => {
	
		let numQuestions = 0;

		//get subject from drop down
		const subject = Object.keys(subjectList)[subjectDropDown.current.value];		
		const subjectFile = subjectList[subject];


		//get type from drop down
		let type;
		switch(typeDropDown.current.value){
			case '1':
				type = 'multi'; 
				break;
			case '2':
				type = 'multi-image';
				break;
			case '3':
				type = 'multi-images';
				break;
			case '4':
				type = 'drag';
				break;
			default:
		}
	
		fetch('http://localhost:5000/get-questions/' + subjectFile, {
			  method: 'GET',
			   headers:{'Content-Type': 'application/json',
				      "Access-Control-Allow-Origin": "*"}}
		).then(response => response.json())
		  .then(
			data => { 
				
				numQuestions = data.questions.length;

				dispatch({type:'update-current-question', question:{...question, id:numQuestions, type:type}});
				dispatch({type:'update-questions', questions:data.questions});
				dispatch({type:'update-subject', subject:subject});
			})
  		  .catch(error => {
    				console.log(error);
		  });
		
	
	};


//------------------------------------------------------------------------
//JSX

	const Menu = () => {
	
		return	(<Row>
				<Col>
				     <Form.Select ref={subjectDropDown}>
					{subjectList && Object.keys(subjectList).map((s, i) => {return (<option key={'sub'+i} value={i}>{s}</option>);})}
				     </Form.Select></Col>
				<Col>
				     <Form.Select  ref={typeDropDown}>
					<option value={1} >Multiple Choice</option>
					<option value={2} >Multiple Choice with Image</option>
					<option value={3} >Multiple Choice Images</option>
					<option value={4} >Drag and Drop</option>
				     </Form.Select>
				</Col>
			<Col><Button onClick={newQuestionHandler} >Create new question</Button></Col>
			
			
			</Row>
			);

	}
//------------------------------------------------------------------------
//Main Render
	return (
		<>
			{ !question && <Menu />	}
			{ question && <Editor subject={subject} question={question} dispatch={dispatch} editMode={false} />}	
		</>
		);	
}
export default AddQuestion;
