import React, {useEffect, useState} from 'react'; 
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PaginationTopics from '../tutorial/PaginationTopics.jsx';

import HTMLDisplay from '../tutorial/HTMLDisplay.jsx';
import useTopics from '../tutorial/useTopics.js'; 
import '../App.css';
import './TutorialPage.css';

const tutorialInv = require('../tutorial_inv.json');

const imagesURL = 'http://localhost:5000/images';



const TutorialPage  = ()=> {

	const [ topics, subject, setSubject ] = useTopics();	
	
  	const [activeTopic, setActiveTopic] = useState();
	const params  = useParams();
	
	useEffect(()=>{	
		console.log("subject:",params.subject, setSubject);
		setSubject(params.subject);
	
	}, [params]);

	

	useEffect(()=>{
		console.log("Topics:", topics);
		topics && setActiveTopic(Object.keys(topics)[0]);
	},[topics]);


	return (
			

		<div className="App">
			<Container >
				<marquee direction="left">	<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
									Site is under Construction
								<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
				</marquee>
			<Row className="App-header">
        			<div id="banner">
                			<img id="icon" src={imagesURL + '/assets/title_icon.png'} alt="logo" />
				</div>
       			 </Row>
        		 {(subject && activeTopic) ?	<HTMLDisplay subject={subject} filename={topics[activeTopic]} /> : null } 

            		 <Row>
				<PaginationTopics activeTopic={activeTopic} topics={topics && Object.keys(topics)} setActiveTopic={setActiveTopic}/>
			</Row>		
	
		</Container>
     	</div>
	);


};
export default TutorialPage;
