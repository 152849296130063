import {useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import parse from 'html-react-parser';


import './MultipleChoice.css';
import Col from 'react-bootstrap/esm/Col';


const letters = ['A','B','C','D','E','F','G','H','I'];

function MultipleChoice({questionNumber, question, id, choices, numAnswers, currentAnswer, dispatch}){ 

//	console.log(questionNumber, question, id, choices, numAnswers, currentAnswer, dispatch);

	const formRef= useRef();
	
	const [boxesState, setBoxesState] = useState(Array(numAnswers).fill(false));
	const [infoText, setInfoText] = useState("Tick " + numAnswers + " boxes");

	useEffect(() => {
		
		const newBoxesState = new Array(numAnswers).fill(false);
		document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
		if(currentAnswer && currentAnswer !== undefined){
			currentAnswer.map((el)=> {
				const index = letters.indexOf(el);
				const box = document.querySelector('#box' + index);
				box.checked = true;
				newBoxesState[index] = true;
			
			});		
		}
		setBoxesState(newBoxesState);		
	}, [questionNumber]);

	useEffect(() => {
		let answer = [];
		boxesState.map((b,i) => {if(b) answer.push(letters[i]);}, answer);
		dispatch({type:'update_answer', id:id, answer:answer});
	
	}, [boxesState]);



	function checkBoxHandler(event,i){

		event.preventDefault();
	 	event.stopPropagation();
	
    	}

	function countChecked(){
		let num = 0;
		boxesState.map((box) => { if(box) num++;	}, num);
		return num;
	}

	const rowHandler = (e,i) =>{
		const num = countChecked();
		if(numAnswers === 1 && num === 1 && !boxesState[i]){
			
			console.log("rowHandler test");
			const newBoxesState = Array(choices.length).fill(false);
			newBoxesState[i] = true;
			setBoxesState(newBoxesState);			
			document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
			const checkedbox = document.querySelector('#box' +i);
			checkedbox.checked = true;
			return;
		
		}	
		if(num === numAnswers - 1 && !boxesState[i]) setInfoText("");
			else setInfoText("Tick " + numAnswers + " boxes");

		if(num === numAnswers && !boxesState[i]){
			return;
		}

		const newBoxesState = [...boxesState];
		newBoxesState[i] = !boxesState[i];
		const checkedbox = document.querySelector('#box' +i); 
		checkedbox.checked = !boxesState[i];
		setBoxesState(newBoxesState);
	};

	//MAIN RENDERING

	return (
		<Container>
		    <p className='question'><b>{questionNumber+1}.</b>  {parse(question)}</p>
		
			{ choices.map((answer, i) => {  
					return ( <Row key={'row'+i}  className='mc-highlight'
						  onClick={(e)=>rowHandler(e,i)}>
						
				  		  <Col xs={10}><span className='mc-ans'><b>{letters[i] + ". "}</b>{parse(answer)}</span></Col>
				<Col>
							<label className="check-container">
								<input type='checkbox' className='custom-checkbox' onClick={(e)=>checkBoxHandler(e, i)} 
								key={"$"+i} id={"box"+i} 
								 />
								<span className="checkmark"></span>
								</label>
						</Col> 
						</Row> );
						})}			

			<Row id="info-row" style={{height:"30px"}}><Form.Text id="info" className="text-end "> {infoText}</Form.Text></Row>		

		</Container>
	);
}

export default MultipleChoice;
