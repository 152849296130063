import  {React, useState, useEffect, useRef} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './ImportQuestions.css'

const ImportQuestions = ({dispatch}) => {

	console.log("ImportQuestions")

	const filename = useRef();	
	const [folderText, setFolderText] = useState();
	const [dirHandle, setDirHandle] = useState();
	const [infoText, setInfoText] = useState();
	const [importQuestions, setImportQuestions] = useState();

	const openFolderPicker = async () => {
			
		const pickerOpts = {
  			mode: "readwrite",
			startIn: dirHandle

		};

		try {
		 const dirHandle = await window.showDirectoryPicker(pickerOpts);
			console.log(dirHandle.name);
			setDirHandle(dirHandle);
			setFolderText(".../" + dirHandle.name + "/")
		} catch (error){ console.error("no file handle or ", error)}
	}


	const openFilePicker = async () => {
		
		const pickerOpts = {
  			types: [
    				{	description: "Questions",
      					accept: {  "application/json": [".json"]},
    				}],
			excludeAcceptAllOption: true,
			multiple: false,
		};
		try {
			const [fileHandle] =  await window.showOpenFilePicker(pickerOpts);
			console.log("fileHandle:", fileHandle)
			filename.current.value = fileHandle.name; 

			fetch('http://localhost:5000/get-questions/' + fileHandle.name, {
				  method: 'GET',
				   headers:{'Content-Type': 'application/json',
					      "Access-Control-Allow-Origin": "*"}}
			).then(response => response.json())
			  .then(
				data => { 
						setImportQuestions(data.questions);
				})
  			  .catch(error => {
    					console.log(error);
		  	});

		} catch (error){ console.error("no file handle or ", error)}
	}

	
	const importButtonHandler = () => {
		console.log("filename :", filename.current.value," dir:",  dirHandle)
		if(  !dirHandle ) {
			setInfoText("Pick Directory");
			setTimeout( () => setInfoText(""), 2000);
			return ;
		}
		if(!filename.current.value ){
			setInfoText("Pick a JSON File")
			setTimeout( () => setInfoText(""), 2000);
			return ;
	
		}
	
	}


//============================================================================================================
// Main Render
	return (	<div className='mb-2'>
		
			<Row>

			
				<Col className='d-flex' xs={3} >
					<Form.Text bsPrefix='folder mt-1' >Folder:</Form.Text>
					<Form.Text bsPrefix='path-text ms-3 mt-1' >{folderText}</Form.Text></Col>
				<Col xs={3}  className='d-flex ms-1 p-0'><Form.Control bsPrefix="text-box" type='text' ref={filename}/>							     
					<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          					<Tooltip {...props}>Choose Folder   </Tooltip>
        					)}
			        		placement="right" >
							<Button className="add-but" onClick={openFolderPicker}><i class="h3 bi bi-folder2"></i></Button>
					</OverlayTrigger>
					<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          					<Tooltip {...props}>Open File Browser   </Tooltip>
        					)}
			        		placement="right" >
							<Button className="add-but" onClick={openFilePicker}><i class="h3 bi bi-filetype-json"></i></Button>
					</OverlayTrigger>

				
				
				</Col>
					
				<Col xs={2} className="d-flex p-1"><Button onClick={importButtonHandler}>Import</Button></Col>
				<Col className='d-flex' xs={3} ><Form.Text bsPrefix='info-text ms-3 mt-1'  >{infoText}</Form.Text></Col>
				<Col></Col>
			</Row>

		
			</div>
		)

}


export default ImportQuestions;
