import React ,{useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import parse from 'html-react-parser';
import './Results.css';

const letters = ['A','B','C','D','E','F','G','H','I'];
const URL = 'http://localhost:5000/';

function Results({userAnswers, questions, subject}){

	const [failedQuestions, setFailedQuestions] = useState([]);
	const [score, setScore] = useState();
	
	 const [showAnsImageModal, setShowAnsImageModal] = useState(false);
	 const [ansImageName, setAnsImageName] = useState();
	 const [ansImageQuestion, setAnsImageQuestion] = useState();
	 const closeAnsImageModal = () => setShowAnsImageModal(false);

	 const openAnsImageModal = (imageName, question) => {
		setAnsImageQuestion(question);
		setAnsImageName(imageName);
		setShowAnsImageModal(true);
	};

	useEffect(() => {
		
		let tempScore = 0;
		let tempFailedQuestions = [];
		const answers = questions.map((q) => {return {id:q.id, type:q.type, answer:q.answer};});	
		answers.map((ans, index)=>{
			console.log(index, ". answer:", userAnswers.get(ans.id), 
					"right answer:", ans.answer, 'id:', ans.id);
			console.log( checkAnswer(ans.type, userAnswers.get(ans.id), ans.answer));
			if(checkAnswer(ans.type, userAnswers.get(ans.id), ans.answer)) tempScore++;
				else tempFailedQuestions.push({...questions[index], number: index});	
		}, userAnswers, questions, tempScore,tempFailedQuestions);

		setScore(tempScore);
		setFailedQuestions(tempFailedQuestions);

		console.log("Results:");	
		console.log("score", tempScore);
		console.log("failedQuestionNums:",tempFailedQuestions);
	}, []);

	const checkAnswer = (type, ans, rightAns) => {
		console.log(type, ans, rightAns);
		if(type === 'multi' || type === 'multi-image')
			return ans.every((a, i)=>{
				return rightAns.includes(a);
			},rightAns);
		if(type === 'drag'){
			return ans.every((a, i)=>{
				return rightAns[i].includes(a);
			},rightAns);
		}
		return false;	
	};

	const highlightAnswer = (expId, ansId) => {

		//get clicked answer & explain
		const answer = document.getElementById(ansId);
		const explain = document.getElementById(expId);
		
		//save status of clicked Answer to toggle it
		let hidden = false;
		if(explain.classList.contains('hidden')) hidden = true;

		// hide and remove highlighting on all answers/explains
		const allAnswers = document.getElementsByClassName('answer');
		for(let i=0;i<allAnswers.length;i++){
			allAnswers[i].classList.remove('highlight');
			allAnswers[i].querySelector("#short-ans").classList.remove('hidden');		
		}

		const allExplain = document.getElementsByClassName('explain');
		for(let i=0;i<allExplain.length;i++){
			allExplain[i].classList.add('hidden');			
		}

		//toggle hightlight answer &  'un'-hide explain
		if(hidden) {
				explain.classList.remove('hidden');
				answer.classList.add('highlight');
				answer.querySelector("#short-ans").classList.add('hidden');
		}
			else {  
				explain.classList.add('hidden');
				answer.classList.remove('highlight');				
				answer.querySelector("#short-ans").classList.remove('hidden');
			}
	};

	const AnsImageModal = ({show, close, question, imageName}) => {
	
		let subjectFolder = subject.toLowerCase().replace(/\s/g, '');
		return (
			<Modal show={show} onHide={close} size="lg">
			        <Modal.Header closeButton>
		        		<Modal.Title>{question}</Modal.Title>
        			</Modal.Header>
			        <Modal.Body><Image src={URL+'questions/images/'+subjectFolder+'/'+imageName}  /></Modal.Body>
			        <Modal.Footer>
				          <Button variant="secondary" onClick={close}> Close </Button>
			        </Modal.Footer>
			</Modal>

		);

	};



	const FailedQuestions = ({failedQuestions, userAnswers}) => {

	
		let subjectFolder = subject.toLowerCase().replace(/\s/g, '');
	
		return(
		<>
		<AnsImageModal show={showAnsImageModal} close={closeAnsImageModal} question={ansImageQuestion} imageName={ansImageName}/>
		<Accordion flush>
		 	{failedQuestions.map((q, i)=>{
				const qAns = userAnswers.get(q.number);
				console.log(i,q.number,qAns);
				return (
					<Accordion.Item bsPrefix="acc-item" eventKey={i} key={'ans-'+i}>			
						<Accordion.Header bsPrefix="res-head" className='p-3'>
							<p className='ans-text h6'>Question : {q.number+1} {q.question}</p>
						</Accordion.Header>
						<Accordion.Body>
						  <ListGroup>
						   {(q.type === 'multi' || q.type === 'multi-image')?
							q.choices.map((c, n) =>{
							const isUserAns = qAns.includes(letters[n]);
							const userAnsClass = (isUserAns)? 'red-bdr' : 'no-bdr';
							return  <ListGroup.Item bsPrefix="ans" className={userAnsClass}>
								<small className='text-muted h6'>{letters[n]} -  {c}</small>
								{(q.answer.includes(letters[n]))?
									 <Image id='tick' src={URL + 'images/assets/tick.png'}
										 width={25} height={25} className='ms-3'/>
									 : null }
									</ListGroup.Item>;
								}) :  null}
						  {(q.type === 'multi-image' || q.type === 'drag')?
								<Image src={URL+'questions/images/'+subjectFolder+'/'+ q.ansImage} width={160} height={80}
								        onClick={()=>openAnsImageModal(q.ansImage, q.question)}/> 
						  : null }
						   </ListGroup>
								<p></p>
								<p>Answer:  {q.answer}</p>
								<p>Your Answer:<span  style={{color:'red'}}>  {qAns.join('')}</span></p>
								<p>Explaination:</p>	
								<p>{q.explain && parse(q.explain)}</p>
						</Accordion.Body>
					</Accordion.Item>

					);
		 	})}
		 </Accordion> 
		</>
		);
	};

	return ( subject && 
		<div id="main">
	     		<Row>
				<Col xs={10}><ProgressBar id="bar" now={  Math.floor((score/questions.length) * 100)} /></Col>
				<Col xs={2}>{Math.floor((score/questions.length) * 100)}%</Col>
			</Row>
			<p id="failed-text">Failed Questions: </p>
			<div id="wrong-answers"><FailedQuestions failedQuestions={failedQuestions} userAnswers={userAnswers}/></div>

	

		</div>
	);


}


export default Results;
