import {useEffect, useState, useReducer} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import QuestionCarousel from './QuestionCarousel.jsx';
import Results from './Results.jsx';
import './Player.css';

const randomQuestions = true;


const validateAnswer = (state) => {
			if(	   state.currentQuestion.type === "multi" 
				|| state.currentQuestion.type === "multi-image" 
				|| state.currentQuestion.type === "multi-images" ){
				console.log(state.currentQuestion, state.answers.get(state.currentQuestion.id));
				if(state.currentQuestion.answer.length !== 
					state.answers.get(state.currentQuestion.id).length) return  false;
			}
			
			if(state.currentQuestion.type === "drag"){
				const answer = state.answers.get(state.currentQuestion.id);
				console.log(answer);
				if(!answer ||	state.answers.get(state.currentQuestion.id).includes('-')) return false;
			}
			return true;
};



const playerReducer =  (state, action) => {
			console.log("player reducer called", action, typeof(action.type));		
		

		if(action.type === 'next' ) {
			//// Validate answer if not first question (currentquestion null, and answer exists in answers set)
			if(state.currentQuestion && state.answers.get(state.currentQuestion.id) && !validateAnswer(state)) return {...state};
			
			//do nothing if no subject
			if(!state.subject) return {...state};
			
			// goto next question / random question
			const newQuestionNumber = state.questionNumber + 1;
			if(randomQuestions){
				const nextQuestionExists = (state.questionQueue[newQuestionNumber])? true : false;
				if(nextQuestionExists){
					console.log("next question exists", state.questionQueue);
					const nextID = state.questionQueue[newQuestionNumber];
					console.log(nextID);
					const newQuestion = state.questions.find((q)=> q.id === nextID);
					console.log(newQuestion);
					return {
						...state, questionNumber: newQuestionNumber, currentQuestion: newQuestion	
					};
				} else {
					
					console.log("adding question", state.questionQueue);
				
				
	
					const filteredQuestions = state.questions.filter(q => {
						if (state.questionQueue.includes(q.id)) return false;
						return true;
					}, state.questionQueue);
					console.log("questions:", state.questions);
					console.log("filteredQuestions:", filteredQuestions);
					
				
				
					if (filteredQuestions.length === 0) {
						return {
							...state, mode:'results'
						};
					}
					const newQuestion = filteredQuestions[Math.floor((filteredQuestions.length * Math.random()))];
					const newQuestionQueue = [...state.questionQueue, newQuestion.id];
					if (filteredQuestions.length === 1) {
						return {
							...state, questionNumber: newQuestionNumber, currentQuestion: newQuestion, questionQueue: newQuestionQueue, isLast:true
						};
					}
					console.log(newQuestionQueue);
					console.log(newQuestion);			
					return {
						...state, questionNumber: newQuestionNumber, currentQuestion: newQuestion, questionQueue: newQuestionQueue
					};
				}
						
			} else {
				if( newQuestionNumber === state.questions.length ){
					return {
						...state, mode:'results'
					};		
				}
				if( newQuestionNumber === state.questions.length - 1) state.isLast = true ;
				return {
					...state, questionNumber: newQuestionNumber, currentQuestion: state.questions[newQuestionNumber]				
				};
      			};
		}	
	
		else if (action.type === 'back') {
	
			//check for beginning of questions
			if(state.questionNumber === 0)
					return {...state};
			const newQuestionNumber = state.questionNumber - 1;								
			console.log(state.questionQueue, state.questions.find((q)=> q.id === state.questionQueue[newQuestionNumber]));
			return {
				...state, questionNumber: newQuestionNumber, currentQuestion: state.questions.find((q)=> q.id === state.questionQueue[newQuestionNumber])						
				};		
		}

		else if (action.type === 'reset') {
		
			return {
				 mode:'questions', questionNumber: -1, subject:null, questions: null, currentQuestion:null,
				  questionQueue: [], answers: new Map(), isLast: false
			};		
		}

		else if (action.type === 'update_answer') {
			
			const newAnswers = new Map(state.answers);
			newAnswers.set(action.id, action.answer);
			return {
				...state,  answers: newAnswers
			};		
		}

		else if (action.type === 'update_questions') {
		
			return {
				...state, mode:'questions', questions:action.questions, questionNumber:-1,
					 questionQueue:[], currentQuestion:action.questions[0]
			};		
		}

		else if (action.type === 'update_subject') {
			
			return {
				...state, subject:action.subject
			};		
		}
	
		else throw Error('Unknown action.');
	
	};


function Player({newSubject}) {
	console.log("new render", newSubject);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

  	const closeConfirmModal = () => setShowConfirmModal(false);
	const openConfirmModal = () => 	setShowConfirmModal(true);			

		
	const [state, dispatch] = useReducer(playerReducer, { mode:'questions', subject: null, questions: null,
							 questionNumber:0, questionQueue : [],  currentQuestion: null, answers: new Map() ,
							isLast: false});

  	

	useEffect(() => {
		if(!newSubject) return;
		if(state.subject){
			 openConfirmModal();				
		}  else {
			dispatch({type:'reset'});
			dispatch({type:'update_subject', subject: newSubject});
		}
 	 }, [newSubject]);
	
	useEffect(()=>{ console.log(state);}, [state]);

	const okHandler = () => {
	
		closeConfirmModal();
		dispatch({type:'reset'});
		dispatch({type:'update_subject', subject: newSubject});
	};


	
/////////////////////////////////////////////////////////////////////////////////////////////////////
// JSX


	const ConfirmModal = ({show, close, selectedSubject, okHandler}) => {
		
		return 	(
			  <Modal show={show} onHide={close}> 
				<Modal.Dialog>
			     	   <Modal.Body>
			        	<p>You will lose your progress in current questions</p>
	        		   </Modal.Body>
			          <Modal.Footer>
	          			<Button variant="secondary" onClick={close}>Cancel</Button>
        	  			<Button variant="primary" onClick={()=>okHandler(selectedSubject)}>OK</Button>
	        		  </Modal.Footer>
      				</Modal.Dialog> 
		  	  </Modal>
		);
	};

	let content = null;

	if( state.mode === 'questions' ){
		content = <QuestionCarousel subject={state.subject} question={state.currentQuestion}
					    questionNumber={state.questionNumber} 
					    dispatch={dispatch} answers={state.answers} isLast={state.islast}/>;
	}

	if( state.mode === 'results' ){
		content =  <Results userAnswers={state.answers}  questions={state.questions} subject={state.subject} />;
	}

/// main render
	return (
		<Container>
			<ConfirmModal show={showConfirmModal} close={closeConfirmModal} okHandler={okHandler} />
			<Row>{content}</Row>
		</Container>
	);
}

export default Player;
