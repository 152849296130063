import React from 'react';
import { createRoot } from 'react-dom/client';
import { Konva,  Stage, Layer, Rect, Text, Circle, Line } from 'react-konva';

const KonvaTest = () => {

	if (typeof window !== 'undefined') {
		  const Konva = require('react-konva');

	const width = window.innerWidth;
	const height = window.innerHeight;

 	
	var stage = new Konva.Stage({
       		container: 'container',
	        width: width,
	        height: height,
	      });

  return (
	<div></div>		
   );
};
}
export default KonvaTest;
