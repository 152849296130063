import React, {useEffect, useState} from "react";
import parse from 'html-react-parser';

const url = 'http://localhost:5000/tutorialhtml/accesscontrollists/whatisacl.htm';

const Topic = () => {

	
	const [html, setHtml] = useState();

	useEffect(()=>{
		
		let data;
	
		const downloadHtml = async () =>{
//			console.log("downloading html subject:",subject," filename:", filename);	

//			console.log(url + 'tutorialhtml/' + subject + '/' + filename );
			fetch(url )
  				.then(function(response) {
				    return response.text();
				  }).then(function(data) {
					console.log(data); // this will be a string
					setHtml(data);
			  });

			
		};
		downloadHtml();
	
	}, []);


	return (<div className='html-display'>
	

	{html ? parse(html) : null}

		</div>
		);

};

export default Topic;
