import React , {useState, useRef, useEffect, useMemo } from 'react';
import {Stage, Layer,  Image, Rect, Text, Group} from 'react-konva';
import useImage from 'use-image';
import './DragDropQuestion.css';

const letters = ['A','B','C','D','E','F','G','H','I'];

const URL = 'http://localhost:5000/' ;

const DragDropQuestion = ({subject, questionNumber, id, question, currentAnswer, dispatch} ) => {
	
	console.log(id);
	const [tempAnswer, setTempAnswer] = useState(Array(question.targets.length).fill('-'));
	const lastQuestionNumber  = useRef();
	const font = 'Calibri';
	const titleFontsize = 35;
	const backgroundColor = "white";

	const dimsRef = useRef({'stageX':800,'stageY':600, 'winX':window.innerWidth, 'winY':window.innerHeight});
	const layerRef = useRef();
	const backgroundRef = useRef();
	const dropsRef = useRef([]);
	const targetsRef = useRef([]);
	const dropTextRefs = useRef([]);
	const infoText= useRef([]);

	const spacing = 10;

	useEffect(() => {
		const info = backgroundRef.current.findOne('#infoText');
		info.attrs.text = "Fill all the boxes in diagram";
	},[tempAnswer] );

	useEffect(() => {

		setTempAnswer(Array(question.targets.length).fill('-'));
		
		// put all drops in start position
	
		dropsRef.current.map((d, i)=> {						
					d.setAbsolutePosition({x: dimsRef.current['stageX'] + 50 ,
						 y: (question.textBoxDims[1] + ((question.textBoxDims[1] + spacing) * i))});
		}, question);
	    

		if(currentAnswer && currentAnswer !== undefined){				// if there is an answer move any drops to targets found in answer
			currentAnswer.map((a)=>{
				if(a!=='-'){
					const drop = dropsRef.current.find((d) => d.attrs.id === a);
					const targetIndex = currentAnswer.indexOf(drop.attrs.id);
					const target = targetsRef.current[targetIndex];
					drop.x(target.attrs.x);
					drop.y(target.attrs.y);	
					} else {
							
					}
			});
			setTempAnswer(currentAnswer);	
		} 
	
	},[questionNumber] );

	if(lastQuestionNumber.current && lastQuestionNumber.current != questionNumber){
		dropsRef.current = [];
		targetsRef.current = [];
	}
	
	lastQuestionNumber.current = questionNumber;

	const removeAnswerById = (value) => {
		if(!tempAnswer || tempAnswer === undefined) return;
		let nextTempAnswer = Array(question.targets.length).fill('-');
		tempAnswer.map((a, i) => {
			if(a !== value) nextTempAnswer[i]= a;
				else nextTempAnswer[i] = '-';
		}, value, nextTempAnswer);
		setTempAnswer(nextTempAnswer);
		dispatch({type:'update_answer', id:id, answer:nextTempAnswer});
	};
		
	const dropDragStartHandler = (event) => {
		event.target.moveToTop();
		removeAnswerById(event.target.attrs.id);
	};
	
	const dropDragEndHandler = (event) => {
	
		const drop = event.target;
		const pointerPosition = event.target.getStage().getPointerPosition();

		const x = pointerPosition.x ;
		const y = pointerPosition.y ;

		let hit;
		let hasHit = false;
	
		targetsRef.current.map((t, index) =>{

			const rect_x = t.attrs.x;
			const rect_y = t.attrs.y;
			const width =  t.children[0].attrs.width;	
			const height = t.children[0].attrs.height;

			if((x > rect_x && x < (rect_x + width))
				&& ((y > rect_y && y < (rect_y + height )))){
				hit = index;
				hasHit = true;		
			}	
		}, hit);		
	
		if(hasHit){
			const targetX = targetsRef.current[hit].attrs.x;				//Snap to target
			const targetY = targetsRef.current[hit].attrs.y;
			drop.absolutePosition({x: targetX, y: targetY});
			
			if(tempAnswer[hit] !=='-'){						// target  already occupied
				const resetDrop = dropsRef.current.find((d) => d.attrs.id === tempAnswer[hit] , tempAnswer);
				removeAnswerById(tempAnswer[hit]);
				console.log(resetDrop);
			}

			const newTempAnswer = [...tempAnswer];
			newTempAnswer[hit] =  drop.attrs.id;
			setTempAnswer(newTempAnswer);
			dispatch({type:'update_answer', id:id, answer:newTempAnswer});
		}
		
	};

	const saveTargetRef = (i, ref) => {
		if(ref) targetsRef.current[i] = ref;
	};

	const saveDropRef = (i, ref) => {
		if(ref) dropsRef.current[i] = ref;
	};


	const limitDrag = (event, i) => {
		const id = event.target.attrs.id;
		const ref = dropsRef.current[letters.indexOf(id)];
		ref.attrs.x = Math.max(ref.attrs.x, 10);
		ref.attrs.x = Math.min(ref.attrs.x, dimsRef.current['winX'] - question.textBoxDims[0] - 10);
		ref.attrs.y = Math.max(ref.attrs.y, Math.max(question.questionFontSize * 3) );
		ref.attrs.y = Math.min(ref.attrs.y, dimsRef.current['stageY'] - question.textBoxDims[1] - 10);
		};

	const resetDrop = (drop) => {
		
		drop.x( dimsRef.current['stageX'] + 50);
		drop.y(question.textBoxDims[1] + ((question.textBoxDims[1] + spacing) * letters.indexOf(resetDrop.attrs.id))) ;
	
	};

	const reset = () => {
		
		dropsRef.current.map((d, i)=>{
			resetDrop(d);
		});
		dispatch({type:'update_answer', questionNumber:questionNumber, answer:null});
		setTempAnswer(Array(question.targets.length).fill('-'));
	};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  		JSX

	const BackgroundImage = ({src}) => {

		const imageRef = useRef(null);
			
		const imageFolder = subject.toLowerCase().replace(/\s/g, '');
		const imageURL = URL + 'questions/images/' + imageFolder + '/' + src;
		const [LoadedImage] = useImage(imageURL);

		return <Image className="image"  ref={imageRef} image={LoadedImage}  />; 
	};


	const Background = () =>{
		return(		<> 
				{(question.image)? <BackgroundImage src={question.image} /> : null}

				<Rect x={dimsRef.current['winX'] - question.textBoxDims[0] - 100} y={0}
					width={question.textBoxDims[0] + 100} height={dimsRef.current['stageY']}
				        fill="grey" shadowBlur={10} />
			
				<Group onClick={reset} x={dimsRef.current['winX'] - 150} y={dimsRef.current['stageY'] - 60}>
					<Rect fill="white" width={100} height={50} cornerRadius={10} stroke="black"></Rect>
					<Text text="Reset" fontSize={22} x={15} y={15}/>
					<Text text="Fill all the boxes " id='infoText' fontSize={24} x={-800} y={30} fill="#527bff"
						 fontStyle="bold" fontFamily="arial"/>
				</Group>
			</>
		);
	};


	const Targets = ({targets}) =>{
		
		return 	 targets  && targets.map((t, i) => {
						
						return <Group 	key={i} id={t.id} ref={el => saveTargetRef(i,el)} x={t.x} y={t.y} >
									<Rect   width={question.textBoxDims[0]} height={question.textBoxDims[1]}
										stroke="black" strokeWidth={2} shadowBlur={5} />
								</Group> ;
					});				
	};

	const Drops = ({ drops}) =>{
				return  drops  &&  drops.map((d, i) => {
					
						return <Group 	key={d.id} id={d.id} 
									ref={el => saveDropRef(i,el) } 
									onDragmove={(event) => limitDrag(event, i)}
									onDragStart={dropDragStartHandler} 
									onDragEnd={(event) => dropDragEndHandler(event, dropsRef.current[i])} 
									draggable={true}  >
								<Rect 	width={question.textBoxDims[0]} height={question.textBoxDims[1]} 
					      				stroke="black" strokeWidth={2} shadowBlur={5} fill={backgroundColor} cornerRadius={5}/> 
								<Text 	ref={el => (dropTextRefs.current[i] = el)} text={d.text}
									fontFamily={font} fontSize={titleFontsize * 0.5} 
									x={10} y={10}/>
							</Group>;
					});
	};
	
	return (
		<>
	 	{ (question && question.question)?  <p id='question' className="ps-4">{questionNumber+1 + ". " + question.question}</p> : null}
		
		<Stage className="stage" width={dimsRef.current['winX']} height={dimsRef.current['stageY']} >

			<Layer ref={backgroundRef}>
				<Background infoText={infoText} />
			
			</Layer>
	
			<Layer ref={layerRef}> 
				{ question.targets  && question.targets.map((t, i) => {
						
						return <Group 	key={i} id={t.id} ref={el => saveTargetRef(i,el)} x={t.x} y={t.y} >
									<Rect   width={question.textBoxDims[0]} height={question.textBoxDims[1]}
										stroke="black" strokeWidth={2} shadowBlur={5} />
								</Group> ;
					})		
				}
				{ question.drops  &&  question.drops.map((d, i) => {
					
						return <Group 	key={d.id} id={d.id} 
									ref={el => saveDropRef(i,el) } 
									onDragmove={(event) => limitDrag(event, i)}
									onDragStart={dropDragStartHandler} 
									onDragEnd={(event) => dropDragEndHandler(event, dropsRef.current[i])} 
									draggable={true}  >
								<Rect 	width={question.textBoxDims[0]} height={question.textBoxDims[1]} 
					      				stroke="black" strokeWidth={2} shadowBlur={5} fill={backgroundColor} cornerRadius={5}/> 
								<Text 	ref={el => (dropTextRefs.current[i] = el)} text={d.text}
									fontFamily={font} fontSize={titleFontsize * 0.5} 
									x={10} y={10}/>
							</Group>;
					})
				}
			
			</Layer> 		
		</Stage>
		</>
	);

}



export default DragDropQuestion;
