import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {  createBrowserRouter,  RouterProvider, useParams, BrowserRouter as Router, Route, Routes} from "react-router-dom";

//import App from './App.jsx';
import HomePage from './pages/HomePage.jsx';
import PlayerPage from './pages/PlayerPage.jsx';
import TutorialPage from './pages/TutorialPage.jsx';
import EditorManager from './editor/EditorManager.jsx';
import LoginPage from './auth/LoginPage.js';
import RegisterForm from './auth/RegisterForm.js';
import AboutPage from './pages/AboutPage.jsx';
import Sample1 from './test/Sample1.jsx';
import Sample2 from './test/Sample2.jsx';

import "./index.css";

import 'bootstrap/dist/css/bootstrap.min.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
    {
    path: "/home",
    element: <HomePage />
  },
  {
    path: "/player/:subject",
    element: <PlayerPage />
  },
  {
    path: "/auth",
    element: <LoginPage />
  },
  {
    path: "/register",
    element: <RegisterForm />
  },
  {
    path: "/editor",
    element: <EditorManager />
  },
  {
    path: "/tutorial/:subject",
    element: <TutorialPage />
  },
  {
    path:"/about",
    element: <AboutPage />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(

    <RouterProvider router={router} />

);

