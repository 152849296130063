import  {React, useState, useEffect, useRef} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './MergeQuestions.css';


const MergeQuestions = ({dispatch}) => {

	const [checkedList, setCheckedList] = useState();
	const [dirHandle, setDirHandle] = useState();
	const [filenameList, setFilenameList] = useState();
	const [folderText, setFolderText] = useState();
	const [importQuestions, setImportQuestions] = useState();
	const [infoText, setInfoText] = useState();
	const [subjectList, setSubjectList] = useState();
	const [showMergeModal,setShowMergeModal] = useState(false);
	const [confirmBackupModal, setConfirmBackupModal] = useState(false );

	const filename = useRef();
	
  	const mergeModalClose = () => setShowMergeModal(false);
	const mergeModalShow = () => setShowMergeModal(true);

	const confirmBackupModalClose = () => setConfirmBackupModal(false);
	const confirmBackupModalShow = () => setConfirmBackupModal(true);


	useEffect  (() => {
		fetch('http://localhost:5000/inv' , {
				  method: 'GET',
				   headers:{'Content-Type': 'application/json',
					      "Access-Control-Allow-Origin": "*"}}
			).then(response => response.json())
			  .then(
				data => { 	//console.log(data);
						setSubjectList(Object.keys(data));
						setFilenameList(Object.values(data));				
				})
  			  .catch(error => {
    					console.log(error);
		  	});
	}, []);

	useEffect  (() => {
		if(importQuestions) setCheckedList(Array(importQuestions.length).fill(false));
	}, [importQuestions]);


	const backupQuestions = async () => {
		
	
		
		console.log(filenameList, subjectList);
		
		filenameList.map((filename)=>{
			fetch('http://localhost:5000/get-questions/' + filename, {
				  method: 'GET',
				   headers:{'Content-Type': 'application/json',
					      "Access-Control-Allow-Origin": "*"}}
			).then(response => response.json())
				  .then(
				async data => { 					
						const exportData = {};
					 	exportData.subject = data.subject;
						exportData.filename = data.filename;
					 	exportData.questions = data.questions;
						const jsonString = JSON.stringify(exportData);
						console.log(jsonString);	

					const res = await ( await fetch('http://localhost:5000/backup/', {
						  method: 'POST',
						  body: jsonString,
						  headers:{'Content-Type': 'application/json',
							     'Accept': 'application/json',
							      "Access-Control-Allow-Origin": "*"},
					  	  redirect: 'manual'
						})
					).json();
			console.log(res); 	 
			if(res.status === 'OK')	confirmBackupModalShow();
						setTimeout(() => confirmBackupModalClose(), 2000);
				})
  			  .catch(error => {
    					console.log(error);
		  	});
		});

	};

	const openFilePicker = async () => {
		
		const pickerOpts = {
  			types: [
    				{	description: "Questions",
      					accept: {  "application/json": [".json"]},
    				}],
			excludeAcceptAllOption: true,
			multiple: false,
		};
		try {
			const [fileHandle] =  await window.showOpenFilePicker(pickerOpts);
			console.log("fileHandle:", fileHandle)
			filename.current.value = fileHandle.name; 

			fetch('http://localhost:5000/get-questions/' + fileHandle.name, {
				  method: 'GET',
				   headers:{'Content-Type': 'application/json',
					      "Access-Control-Allow-Origin": "*"}}
			).then(response => response.json())
				  .then(
				data => { 
						setImportQuestions(data.questions);
				})
  			  .catch(error => {
    					console.log(error);
		  	});

		} catch (error){ console.error("no file handle or ", error)}
	}

	
	const openFolderPicker = async () => {
			
		const pickerOpts = {
  			mode: "readwrite",
			startIn: dirHandle

		};

		try {
		 const dirHandle = await window.showDirectoryPicker(pickerOpts);
			console.log(dirHandle.name);
			setDirHandle(dirHandle);
			setFolderText(".../" + dirHandle.name + "/")
		} catch (error){ console.error("no file handle or ", error)}
	};


	const mergeButtonHandler = () => {
		console.log("filename :", filename.current.value," dir:",  dirHandle)
		if(  !dirHandle ) {
			setInfoText("Pick Directory");
			setTimeout( () => setInfoText(""), 2000);
			return ;
		}
		if(!filename.current.value ){
			setInfoText("Pick a JSON File")
			setTimeout( () => setInfoText(""), 2000);
			return ;
	
		}
		mergeModalShow();
	};



//================================================================================================================================
//		JSX 
	const ConfirmBackup = ({show, close}) => {
	
	return (
	<Modal show={show} onHide={close}>
        			<Modal.Header >
			        </Modal.Header>
	      			<Modal.Body>
					<p style={{'font-weight':'bold','font-size':'22px','text-align':'center'}}> Backup Saved. </p>	
				</Modal.Body>
			</Modal>
	);
	
}




	const QuestionList = ({items}) => {
		
	 	const selectItemHandler = (e, n) => {
			setCheckedList({ ...checkedList, [n]:e.target.checked });
		};

 		return (<ListGroup className='merge-box' id='q-box'>
		 	<ListGroup.Item className='title-item'>
				    <span className='title-item ps-3' style={{'width':'5%'}}>ID</span>
	 			    <span className='title-item' style={{'width':'20%'}}>Type</span>
	 			    <span className='title-item' style={{'width':'70%'}}>Question</span>
 				</ListGroup.Item>
	 			{ items && items.map((item,n) => { return (	<ListGroup.Item className='listItem'>
 											<input className='p-2 ms-2' type="checkbox" onChange={(e)=>selectItemHandler(e,n)} 
												checked={(checkedList)? checkedList[n] : false} />
											<span className='ms-2' style={{'width':'5%'}}>{item.id}</span>
											<span className='ms-2 p-2' style={{'width':'20%'}}>{item.type}</span>
											<span className='p-2' style={{'width':'75%'}}>{(item.question).substr(0,100)}</span>	
 										</ListGroup.Item>); })}
 				</ListGroup>	
			);
	};

	const MergeModal = ({show, hide}) => {
	
		
		const [destinationFile, setDestinationFile] = useState();
		const [exportSubject, setExportSubject] = useState();

		const subjectHandler = (e) =>{
			console.log(subjectList, filenameList);
			console.log(filenameList[e.target.value]);
			console.log(subjectList[e.target.value]);
			const filename = filenameList[e.target.value];
			const subject = subjectList[e.target.value];
			setDestinationFile(filename);
			setExportSubject(subject);
		};

		const mergeHandler= () => {
		
			console.log(checkedList);
			console.log(importQuestions);

			let exportQuestions = [];
			Object.values(checkedList).map((q, i)=>{
				if(q) exportQuestions = [...exportQuestions, importQuestions[i] ];	
			});
			console.log(exportQuestions);

			fetch('http://localhost:5000/get-questions/' + destinationFile, {
				  method: 'GET',
				   headers:{'Content-Type': 'application/json',
					      "Access-Control-Allow-Origin": "*"}}
			).then(response => response.json())
			  .then(
				data => { 
						console.log(data.questions, exportQuestions);
						exportQuestions = [...exportQuestions, ...data.questions ];
						console.log(exportQuestions);				
						dispatch({type:'update-questions', questions:exportQuestions});
						dispatch({type:'update-subject', subject:exportSubject});
						dispatch({type:'update-mode', mode:'questions'});
			}).catch(error => {
    					console.log(error);
			});	
		};
		////////////Modal Render
		console.log("render");
		return (
			<Modal show={showMergeModal} onHide={mergeModalClose}>
		        <Modal.Header closeButton>
		          <Modal.Title>Modal heading</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>Import Question Set</Modal.Body>
				<Form.Text>Pick Subject To Import to:</Form.Text>
				<Form.Select onChange={subjectHandler} value={subjectList && subjectList.indexOf(exportSubject)}>
      						<option></option>
					        { subjectList && subjectList.map((s, i)=>{
								return ( <option value={i} key={'q'+i}>{s}</option>);
						})}
    				</Form.Select>
			        <Modal.Footer>
		          		<Button variant="secondary" onClick={mergeModalClose}>Close</Button>
		          		<Button variant="primary" onClick={mergeHandler}>Merge</Button>
		        	</Modal.Footer>
		      </Modal>
		);
	}

//============================================================================================================
// Main Render
	return (	<div className='mb-2'>
			<MergeModal show={showMergeModal} hide={mergeModalClose}/>
			<ConfirmBackup show={confirmBackupModal} hide={confirmBackupModalClose}/>
			<Row>

			
				<Col className='d-flex' xs={3} >
					<Form.Text bsPrefix='folder mt-1' >Folder:</Form.Text>
					<Form.Text bsPrefix='path-text ms-3 mt-1' >{folderText}</Form.Text></Col>
				<Col xs={3}  className='d-flex ms-1 p-0'><Form.Control bsPrefix="text-box" type='text' ref={filename}/>							     
					<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          					<Tooltip {...props}>Choose Folder   </Tooltip>
        					)}
			        		placement="right" >
							<Button className="add-but" onClick={openFolderPicker}><i class="h3 bi bi-folder2"></i></Button>
					</OverlayTrigger>
					<OverlayTrigger delay={{ hide: 450, show: 300 }}  overlay={(props) => (
          					<Tooltip {...props}>Open File Browser   </Tooltip>
        					)}
			        		placement="right" >
							<Button className="add-but" onClick={openFilePicker}><i class="h3 bi bi-filetype-json"></i></Button>
					</OverlayTrigger>
				
				
				
				</Col>
					
				<Col xs={2} className="d-flex p-1"><Button onClick={mergeButtonHandler}>Merge</Button></Col>
				<Col xs={2}>	<Button className="backup-but" onClick={backupQuestions}>Backup</Button></Col>
				<Col className='d-flex' xs={3} ><Form.Text bsPrefix='info-text ms-3 mt-1'  >{infoText}</Form.Text></Col>
				<Col></Col>
			</Row>

			<QuestionList items={importQuestions} checkedList={checkedList}/>
			</div>
		);
}


	

	

export default MergeQuestions;

