import  React,  {useState, useRef, useEffect, useLayoutEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import './MultiChoiceImage.css';
import parse from 'html-react-parser';

const letters = ['A','B','C','D','E','F','G','H','I'];
const URL = 'http://localhost:5000/';

const MultiChoiceImage = ({questionNumber, question, id, subject, image, size, choices, numAnswers, currentAnswer, dispatch }) => {
	
	console.log(questionNumber, question, currentAnswer);

	const formRef= useRef();
	
	const [boxesState, setBoxesState] = useState(Array(choices.length).fill(false));
	const [infoText, setInfoText] = useState("Tick " + numAnswers + " boxes");

	
	useEffect(() => {
		
		const newBoxesState = new Array(numAnswers).fill(false);
		document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
		if(currentAnswer && currentAnswer !== undefined){
			currentAnswer.map((el)=> {
				const index = letters.indexOf(el);
				const box = document.querySelector('#box' + index);
				box.checked = true;
				newBoxesState[index] = true;
			
			});		
		}
		setBoxesState(newBoxesState);		
	}, [questionNumber]);


	useEffect(() => {
		let answer = [];
		boxesState.map((b,i) => {if(b) answer.push(letters[i]);}, answer);
		dispatch({type:'update_answer', id:id, answer:answer});
	
	}, [boxesState]);
	
	const checkBoxHandler = (event,i) => {

		event.preventDefault();
	 	event.stopPropagation();
    	};

	const rowHandler = (e,i) =>{	console.log("rowHandler:", e, i);
		const num = countChecked();
		if(numAnswers === 1 && num === 1 && !boxesState[i]){
			const newBoxesState = Array(choices.length).fill(false);
			newBoxesState[i] = true;
			setBoxesState(newBoxesState);			
			document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
			const checkedbox = document.querySelector('#box' +i);
			checkedbox.checked = true;
			return;
		
		}	
		if(num === numAnswers - 1 && !boxesState[i]) setInfoText("");
			else setInfoText("Tick " + numAnswers + " boxes");

		if(num === numAnswers && !boxesState[i]){
			return;
		}

		const newBoxesState = [...boxesState];
		newBoxesState[i] = !boxesState[i];
		const checkedbox = document.querySelector('#box' +i); 
		checkedbox.checked = !boxesState[i];
		setBoxesState(newBoxesState);
	};
	function countChecked(){
		let num = 0;
		boxesState.map((box) => { if(box) num++;	}, num);
		return num;
	}

	//MAIN RENDERING
	let folderName =  subject.toLowerCase().replace(/\s/g, '');
	console.log(question);
	return (
		<Container classname='container' fluid>
		
			<Row>
				<Col><Image  className='mcp-image'
					     src={URL + 'questions/images/' + folderName + '/' + image}
						     width={size.x} height={size.y} fluid/></Col>
			</Row>
		
			 <Row>
			    <p id='mcp-question' >{questionNumber+1}.  {question}</p></Row>
			<Row>
			<Row>
		          <Form className="answers  ps-2" ref={formRef} >
			   	 { choices.map((answer, i) => {  
					return (<Row key={'row'+i}  className='mcp-highlight'
						  onClick={(e)=>rowHandler(e,i)}>
					
				  		  <Col xs={6}  className='mcp-ans'>
							<Row>
								<Col xs={10} className='p-0 m-0'><span className='letter'>{letters[i] + ". "}</span>
									<span className='p-0 m-0'>{parse(answer)}</span></Col>						 
								<Col><label className="mcp-check-container">
									<input type='checkbox' className='mcp-custom-checkbox'
										 onClick={(e)=>checkBoxHandler(e, i)} 
										key={"$"+i} id={"box"+i} />
								<span className="mcp-checkmark"></span>	</label></Col></Row>
						</Col> 
						 
						</Row> );
						})}	
			   
			  </Form>
			</Row>		
			<Row id="info-row" style={{height:"30px"}}><Form.Text id="info" className="text-end "> {infoText}</Form.Text></Row>
			</Row>		

		</Container>
	);
	
	
}

export default MultiChoiceImage;
