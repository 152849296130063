import  React,  {useState, useRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './MultiChoiceImages.css';


const URL = 'http://localhost:5000/';
const letters = ['A','B','C','D','E','F','G','H','I'];


const MultiImages = ({questionNumber, question, id, subject, choices, numAnswers, currentAnswer, dispatch}) => {
		
	const [boxesState, setBoxesState] = useState(Array(choices.length).fill(false));
	const [infoText, setInfoText] = useState("Tick " + numAnswers + " boxes");

	useEffect(() => {
		let answer = [];
		boxesState.map((b,i) => {	if(b) answer.push(letters[i]); }, answer);
		console.log(answer);
		dispatch({type:'update_answer',id:id, answer:answer});
	
	}, [boxesState]);
	
		useEffect(() => {
		
		const newBoxesState = new Array(numAnswers).fill(false);
		document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
		if(currentAnswer && currentAnswer !== undefined){
			currentAnswer.map((el)=> {
				const index = letters.indexOf(el);
				const box = document.querySelector('#box' + index);
				box.checked = true;
				newBoxesState[index] = true;
			
			});		
		}
		setBoxesState(newBoxesState);		
	}, [questionNumber]);

	
	const checkBoxHandler = (event,i) => {
		event.preventDefault();
	 	event.stopPropagation();	
    	};

	const rowHandler = (e,i) =>{
			console.log("rowHandler:", e, i);
		const num = countChecked();
		if(numAnswers === 1 && num === 1 && !boxesState[i]){
			
			console.log("rowHandler test");
			const newBoxesState = Array(choices.length).fill(false);
			newBoxesState[i] = true;
			setBoxesState(newBoxesState);			
			document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
			const checkedbox = document.querySelector('#box' +i);
			checkedbox.checked = true;
			return;
		
		}	
		if(num === numAnswers - 1 && !boxesState[i]) setInfoText("");
			else setInfoText("Tick " + numAnswers + " boxes");

		if(num === numAnswers && !boxesState[i]){
			return;
		}

		const newBoxesState = [...boxesState];
		newBoxesState[i] = !boxesState[i];
		const checkedbox = document.querySelector('#box' +i); 
		checkedbox.checked = !boxesState[i];
		setBoxesState(newBoxesState);
		console.log(newBoxesState);
	};

	function countChecked(){
		let num = 0;
		boxesState.map((box) => { if(box) num++;	}, num);
		return num;
	}



	let folderName =  subject.toLowerCase().replace(/\s/g, '');

	return ( 
		<Container className='question-box '>
			<p id='question'>{questionNumber+1 + '. ' + question}</p>
			{ choices.map((c, i) => { 

				return (

					<Row key={'row'+i} className='question-row' onClick={(e)=>rowHandler(e,i)}>
						<div classsName='highlight'/>
						<Col ><span className='letter'>{letters[i] + ". "}</span></Col>
						<Col xs={8}>	<img className='ans' src={URL + 'questions/images/' + folderName + '/' + c} />
							</Col>
						<Col>< label className="check-container">
							<input className='custom-checkbox' type='checkbox'
								    id={'box' + i} inline
								    onClick={(e)=>checkBoxHandler(e,i)} />
							<span className="mci-checkmark"></span>
							</label>
						</Col>
						
					</Row>);}) }
				<Row><p className='info'>{infoText}</p></Row>
		</Container>
	);
};

export default MultiImages;
