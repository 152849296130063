import { useState, useEffect } from "react";

const useTopics = () => {

  const [subject, setSubject] = useState();
  const [topics, setTopics] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

const url = 'http://localhost:5000/topics/';

  useEffect( ()=>{
	console.log("UE", subject);
	if(!subject) return;
	const fetchData = async () => {
		setLoading(true);
		try {
        		const response = await fetch(url + subject );
			const json = await response.json();
			console.log(json);
			setTopics(json);
		} catch (error) {
	        		setError(error);
      		} finally {
        		setLoading(false);
     		 }				
	};
	fetchData();		
  }, [subject]);
  
  return [topics, subject, setSubject];
};

export default useTopics;
