import {useEffect, useState} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';

import './PaginationTopics.css';


const linkStyle = {
	color: '#282c34',
	height:'40px',
	width: '50px',
	textAlign:'center'
}
const linkStyleText = {
	color: '#282c34',
	height:'40px',
	width: '20vw',
	textAlign:'center'
}


 const PaginationTopics = ({ activeTopic, topics, setActiveTopic}) => {

	const [visibleTopics, setVisibleTopics] = useState([]);
	const [active, setActive] = useState(1);
	
	useEffect(() =>{
		console.log(activeTopic);
		if(!topics) return;
		const index = topics.indexOf(activeTopic);
		const len = topics.length;
		if(index <= 1){
			setVisibleTopics(()=>[topics[0],topics[1], topics[2]]);
			if(index === 0 )setActive(()=>1);
				else setActive(()=>2);	
		} else if(index >= len-2){
			setVisibleTopics(()=>[topics[len-3],topics[len-2], topics[len-1]]);
			if(index===len-1) setActive(()=>3);	
				else setActive(()=>2);
		} else {	
			setVisibleTopics(()=>[topics[index - 1],topics[index], topics[index+1]]);
				setActive(()=>2);
		}
		
	
	
	
	},[activeTopic]);
	



  const highlight = (activeTopic) =>{

		//highlight logic
		const left = document.getElementById('left');
		const center = document.getElementById('center');
		const right = document.getElementById('right');
	
		if(left.innerText === activeTopic){
			setActive(()=>1);
			return;
		}
		if(center.innerText === activeTopic){
			setActive(()=>2);
			return;
		}
		if(right.innerText === activeTopic){
			setActive(()=>3);
		}
}
	
  const clickHandler = (e) => {
	const targetText = e.target.innerText;
	const regex = /\([^()]*\)/i;
	console.log(targetText.replace(regex, '').trim());
	const subjectText = targetText.replace(regex, '').trim();
	setActiveTopic(subjectText);
  };
  const startHandler = (e) => {	
	setActiveTopic(topics[0]);
  };
  const backHandler = (e) => {
	const index = topics.indexOf(activeTopic);
	console.log(activeTopic, index);
	if(index==0) return;
	setActiveTopic(topics[index-1]);
  };
  const nextHandler = (e) => {
	const len = topics.length;
	const index = topics.indexOf(activeTopic);

	console.log(activeTopic, index);
	if(index==len-1) return;
	setActiveTopic(topics[index+1]);
  };
  const endHandler = (e) => {
	setActiveTopic(topics[topics.length-1]);
  };

  return (
  <Container className='box'>
    <Pagination className='pagination-box' >
      <Pagination.First onClick={startHandler}  linkStyle={linkStyle} />
      <Pagination.Prev onClick={backHandler}  linkStyle={linkStyle}/>
      <Pagination.Ellipsis  linkStyle={linkStyle}/>

      <Pagination.Item linkStyle={linkStyleText} id='left' onClick={clickHandler} active={active===1}>{visibleTopics[0]}</Pagination.Item> 
      <Pagination.Item linkStyle={linkStyleText} id='center' onClick={clickHandler} active={active===2}>{visibleTopics[1]}</Pagination.Item>
    <Pagination.Item  linkStyle={linkStyleText} id='right' onClick={clickHandler} active={active===3}>{visibleTopics[2]}</Pagination.Item> 

      <Pagination.Ellipsis  linkStyle={linkStyle} />
      <Pagination.Next onClick={nextHandler}  linkStyle={linkStyle}/>
      <Pagination.Last onClick={endHandler}  linkStyle={linkStyle}/>
    </Pagination>
  </Container>
  );
}

export default PaginationTopics;
