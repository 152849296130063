import {useState, useRef, React} from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
//bootstrap
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import parse from 'html-react-parser';

import './QuestionAccordian.css';

const letters = ['A','B','C','D','E','F','G','H','I'];
const URL = 'http://localhost:5000/'


const QuestionAccordian = ({questions, dispatch, subject}) => {

				console.log(questions)

				const SortButtons = ({upHandler, downHandler}) => {
					return  <div className="buttons d-inline-flex flex-column" > 
							<Button className="sort-but" onClick={upHandler}><i className="bi bi-arrow-up-short"></i></Button>
							<Button className="sort-but" onClick={downHandler}><i className="bi bi-arrow-down-short"></i></Button>
						</div>	
				}

				const upHandler = (e, id) => {
					e.preventDefault();
					e.stopPropagation();
					const newQuestions = [...questions];
					const index = questions.findIndex((q) => q.id === id, id);
					if(index === 0 ) return;
					const q  = questions[index]
					const prevQ = questions[index-1]
					newQuestions[index] = prevQ;
					newQuestions[index-1] = q;
					dispatch({type:'update-questions', subject: subject, questions: newQuestions })
					
				}
				
				const downHandler = (e, id) => {
					e.stopPropagation();
					e.preventDefault();
					const newQuestions = [...questions];
					const index = questions.findIndex((q) => q.id === id, id);
					if(index === questions.length - 1) return;
					const q  = questions[index]
					const nextQ = questions[index+1]
					newQuestions[index] = nextQ;
					newQuestions[index+1] = q;
					dispatch({type:'update-questions', subject: subject, questions: newQuestions })
				}
	
				const openEditor = (question) => {
					dispatch({type:'update-current-question', question:question});
					dispatch({type:'update-edit-mode', editMode:true})
					dispatch({type:'update-mode', mode:'edit'})
				}

				const deleteQuestion = (id) => {
					dispatch( { type:'delete', id:id } )
				}


	if(subject === null) return;

	let folderName =  subject.toLowerCase().replace(/\s/g, '');

	return (
			
				questions && <Accordion  key={questions.subject} style={{fontSize:"16px"}}>

						{questions.map((question, index) => {
						//console.log(question, question.id)
						  if(question.type === 'multi'){
								return (<Accordion.Item className="accordian-q" key={"Q" + index} eventKey={index}>
        								<Accordion.Header>
										<ListGroup  className="accordian-q d-flex flex-row">
											<ListGroup.Item id="id" className="border-0 h5 me-2">
												<SortButtons upHandler={(e)=>upHandler(e, question.id)}
													     downHandler={(e)=>downHandler(e, question.id)} />
											</ListGroup.Item>
											<ListGroup.Item id="id" className="border-0 h6 id-width">ID:{question.id}</ListGroup.Item>
											<ListGroup.Item className="border-0">{question.question}</ListGroup.Item>
										</ListGroup>
									</Accordion.Header>
        								<Accordion.Body>
										<ListGroup className="list-group">
		       								{ question.choices &&  question.choices.map((choice, index) => 				
										  <ListGroup.Item key={ index}>
												<div className="d-flex flex-row ">
													<p className="fw-bold mx-3">{letters[index]}.</p>
													<p className="flex-grow ">{choice}</p>								
												</div>	
										  </ListGroup.Item>							 
										 	)}
										  <ListGroup.Item> Answer:  {question.answer}</ListGroup.Item>
										  <ListGroup.Item>{(question.explain)? parse(question.explain) : null} </ListGroup.Item>
										  <ListGroup.Item >
									 		<Button className="w-1 rounded-3 m-2" enabled="true" onClick={()=>openEditor(question)}>Edit</Button>
						 					<Button className="w-1 rounded-3 m-2" onClick={()=>deleteQuestion(question.id)} variant="danger">Delete</Button>
										  </ListGroup.Item>
										</ListGroup>
									</Accordion.Body>
      							</Accordion.Item>)
						  }
			  if(question.type === 'multi-image'){
								return (<Accordion.Item key={"Q" + index} eventKey={index}>
        								<Accordion.Header>
										<ListGroup  className="d-flex flex-row">
											<ListGroup.Item >
												<SortButtons upHandler={(e)=>upHandler(e, question.id)}
													     downHandler={(e)=>downHandler(e, question.id)} />
											</ListGroup.Item>
											<ListGroup.Item id="id" className="id-width border-0 fw-bold me-2">ID:{question.id}</ListGroup.Item>
											<ListGroup.Item className="border-0">{question.question}</ListGroup.Item>													   </ListGroup>
									</Accordion.Header>
        								<Accordion.Body>
										<Image src={URL + 'questions/images/' + folderName + '/' + question.image} className='w-25'/>
										<ListGroup className="list-group">
		       								{ question.choices &&  question.choices.map((choice, index) => 				
										 	  <ListGroup.Item key={ index}>
												<div className="d-flex flex-row ">
													<p className="fw-bold mx-3">{letters[index]}.</p>
													<p className="flex-grow ">{choice}</p>								
												</div>	
										  </ListGroup.Item>								 
										 	)}
										  <ListGroup.Item> Answer:  {question.answer}</ListGroup.Item>
										  <ListGroup.Item>{(question.explain)? parse(question.explain) : null} </ListGroup.Item>
										  <ListGroup.Item >
									 		<Button className="w-1 rounded-3 m-2"  enabled="true" onClick={()=>openEditor(question)}>Edit</Button>
						 					<Button className="w-1 rounded-3 m-2" variant="danger" onClick={()=>deleteQuestion(question.id)} >Delete</Button>
										  </ListGroup.Item>
										</ListGroup>
									</Accordion.Body>
      							</Accordion.Item>)
						  }
			  if(question.type === 'multi-images'){
								return (<Accordion.Item key={"Q" + index} eventKey={index}>
        								<Accordion.Header>
										<ListGroup  className="d-flex flex-row">
											<ListGroup.Item id="id" className="border-0 h5 me-2">
												<SortButtons upHandler={(e)=>upHandler(e, question.id)}
													     downHandler={(e)=>downHandler(e, question.id)} />
											</ListGroup.Item>
											<ListGroup.Item id="id" className="id-width border-0 fw-bold me-2">ID:{question.id}</ListGroup.Item>
											<ListGroup.Item className="border-0">{question.question}</ListGroup.Item>													   </ListGroup>
									</Accordion.Header>
        								<Accordion.Body>
										<ListGroup className="list-group">
		       								{ question.choices &&  question.choices.map((choice, index) => 				
										  <ListGroup.Item key={ index}>
												<div className="d-flex flex-row ">
													<p className="fw-bold mx-3">{letters[index]}.</p>
													<Image src={URL+ 'questions/images/' + folderName + '/' + choice}></Image>						
												</div>	
										  </ListGroup.Item>							 
										 	)}
										  <ListGroup.Item> Answer:  {question.answer}</ListGroup.Item>
										  <ListGroup.Item>{(question.explain)? parse(question.explain) : null} </ListGroup.Item>
										  <ListGroup.Item >
									 		<Button className="w-1 rounded-3 m-2"  enabled="true" onClick={()=>openEditor(question)}>Edit</Button>
						 					<Button className="w-1 rounded-3 m-2" variant="danger" onClick={()=>deleteQuestion(question.id)} >Delete</Button>
										  </ListGroup.Item>
										</ListGroup>
									</Accordion.Body>
      							</Accordion.Item>)
						  }

			  if(question.type === 'drag'){
							
								return (<Accordion.Item key={"D" + index} eventKey={index}>
        								  <Accordion.Header>
										<ListGroup  className="d-flex flex-row">
											<ListGroup.Item id="id" className="border-0 h5 me-2">
												<SortButtons upHandler={(e)=>upHandler(e, question.id)}
													     downHandler={(e)=>downHandler(e, question.id)} />
											</ListGroup.Item>
											<ListGroup.Item id="id" className="id-width border-0 fw-bold me-2">ID:{question.id}</ListGroup.Item>
											<ListGroup.Item className="border-0">{question.question}</ListGroup.Item>
										</ListGroup>
									  </Accordion.Header>
        								  <Accordion.Body>
										<Image src={URL + 'questions/images/' + folderName + '/' + question.image} className='w-25'/>
										<ListGroup className="list-group">
										  <ListGroup.Item >
									 		<Button className="w-1 rounded-3 m-2"  enabled="true" onClick={()=>openEditor(question)}>Edit</Button>
						 					<Button className="w-1 rounded-3 m-2" variant="danger" onClick={()=>deleteQuestion(question.id)} >Delete</Button>
										  </ListGroup.Item>
										</ListGroup>
									  </Accordion.Body>
      									  </Accordion.Item>)
						  }
						})}
					</Accordion>
				)
	
}

export default QuestionAccordian;
