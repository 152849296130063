import {useState, useRef, React} from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
//bootstrap
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

import QuestionAccordian from './QuestionAccordian.js';
import { save } from 'save-file';
import './QuestionDisplay.css';


//////////////////////////////////////////////////////////////////////////////////////////
//            METHODS									//
//////////////////////////////////////////////////////////////////////////////////////////




//////////////////////////////////////////////////////////////////////////////////////////
//		JSX									//
//////////////////////////////////////////////////////////////////////////////////////////
//		New Subject Modal
/////////////////////////////////////////////////

const ConfirmExport = ({show, close}) => {
	
	return (
	<Modal show={show} onHide={close}>
        			<Modal.Header >
			        </Modal.Header>
	      			<Modal.Body>
					<p style={{'font-weight':'bold','font-size':'22px','text-align':'center'}}> File Saved. </p>	
				</Modal.Body>
			</Modal>
	);
	
}


const NewSubjectModal = ({show, close, dispatch}) => {

	const subjectTitleRef = useRef();
	const subjectFilenameRef = useRef();

	const saveHandler = async () => {
		const name = subjectTitleRef.current.value;
		const filename = subjectFilenameRef.current.value;

		const exportData = {};
	 	exportData.subject = name;
	 	exportData.questions = [];
		const jsonString = JSON.stringify(exportData);
	 	const blob =  new Blob([jsonString], { type: "application/json" });	
		
		await save(blob, filename);
	
		dispatch({type: 'new-subject', subject: name,  filename: 'filename'});
	};
	
	const updateInvHandler = async () => {

		const name = subjectTitleRef.current.value;
		const filename = subjectFilenameRef.current.value;

		let inventory =  await fetch('./questions/subject_inv.json')
			.then(response => {
				  return response.json()
			  }).catch(err => {
				  console.log(err);
			});
		
		inventory[name] = filename;	
		const jsonString = JSON.stringify(inventory);
	 	const blob =  new Blob([jsonString], { type: "application/json" });
		save(blob, 'subject_inv.json');

	};

	return (
	<Modal show={show} onHide={close}
				size='lg'>
        			<Modal.Header closeButton>
       		  			<Modal.Title>Add Subject</Modal.Title>
			        </Modal.Header>
	      			<Modal.Body>
					<Form>
						<Form.Label>Subject :</Form.Label>
						<Form.Control type="text"  ref={subjectTitleRef} />
						<Form.Label>Filename :</Form.Label>
						<Form.Control type="text"  ref={subjectFilenameRef} />
					</Form>
				</Modal.Body>
        			<Modal.Footer>
	          			<Button onClick={close}>Close</Button>
					<Button onClick={saveHandler} >Save</Button>
					<Button onClick={updateInvHandler} >Update Inv</Button>					
			       	</Modal.Footer>
			</Modal>
	);
}

//		Subject Picker DropDown
///////////////////////////////////////////////////////////////////////////////	
const SubjectPicker = ({ subject, dispatch, showSubjectModal, exportQuestions}) => {		

		const filenameRef = useRef();
		
		const dir = useRef(require.context('..'));
		const subjectInv = dir.current('./subject_inv.json');
		const subjectList = Object.keys(subjectInv);
		const filesList = Object.values(subjectInv);
		
		const subjectNum = subjectList.indexOf(subject);
		const file = filesList[subjectNum];

		const subjectPickerHandler = (event) => {
			const name = subjectList[event.target.value];
			console.log("name", name)
			const file = filesList[event.target.value];
			dispatch({type:'load', subject:name, filename:file});
		};

		const exportHandler = () => {
			const filename = filenameRef.current.value;
			console.log(filename);
			exportQuestions(filename);
		};
		
		const renumberHandler = () => {
			dispatch({type:'renumber'});
		};

		const searchHandler = async () => {
			let filehandle;
			try{ 
				filehandle = await window.showOpenFilePicker();
			} catch(error) { 	console.log(error)
			}
			if(filehandle)	document.getElementById('file-input').value= filehandle[0].name;
			
		};

		return (	subjectList &&
					<Container>
					<Row>
						<Col xs={4} classname='m-0'>
							<Form.Select  id="drop-down" defaultValue={subjectNum} 
								onChange={(event) => subjectPickerHandler(event)}>
						      		<option>Select Question Set</option>
								{ subjectList.map((s, i) => <option key={"sub"+i} value={i}>{s}</option>)}
							</Form.Select>
						</Col>
						<Col xs={1} className='m-0 p-0' id='col-but'>
							<OverlayTrigger delay={{ hide: 450, show: 300 }}
									overlay={(props) => ( <Tooltip {...props}>Add Subject</Tooltip>	)} placement="right" >
								<Button id="add-subject-but"  className='but float-left mb-2' onClick={showSubjectModal}>				
							<i className="folder-icon bi bi-file-plus btn-lg"></i></Button>
							</OverlayTrigger>
						</Col>
						<Col xs={3} id='filename-label-col'><p id="filename-label">Filename :</p></Col>
						  <Col xs={3} className="m-0"><Form.Control className="filename-input" type="text" 
											    id="file-input" ref={filenameRef} defaultValue={file}/></Col>
						   <Col><Button className='but' onClick={renumberHandler}>Renumber</Button>	</Col>
					</Row>
					<Row>
						 <Col xs={3} className="col-but m-0" >										
							<Button className='but' onClick={exportHandler}>Export</Button>
							<Button className='but' onClick={searchHandler}>
								<i class="folder-icon bi bi-search"></i></Button>
						  </Col>	
					
					</Row>
					</Container>
		);
	};



//////////////////////////
//	MAIN RENDER	//
//////////////////////////

const QuestionDisplay = ({subject, questions, filename, dispatch, exportQuestions}) => {


	const [confirmExportModal, setConfirmExportModal] = useState(false);
	const [newSubjectModal, setNewSubjectModal] = useState(false);

	const newSubjectModalClose = () => setNewSubjectModal(false);
	const newSubjectModalShow = () => setNewSubjectModal(true);
	const confirmExportClose = () => setConfirmExportModal(false);
	const confirmExportShow = () => setConfirmExportModal(true);

	const showConfirm = () => {
		confirmExportShow();
		setTimeout(()=> confirmExportClose(), 1000);
	};

	return (		<>
				<ConfirmExport  show={confirmExportModal} close={confirmExportClose} />
				<NewSubjectModal show={newSubjectModal} close={newSubjectModalClose} dispatch={dispatch}/>
				<SubjectPicker subject={subject} dispatch={dispatch} showSubjectModal={newSubjectModalShow} 
						filename={filename} exportQuestions={()=>exportQuestions(showConfirm)}/>
				<QuestionAccordian questions={questions} dispatch={dispatch} subject={subject}/>
				</>



	);


}

export default QuestionDisplay;
