import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Menu from '../Menu.jsx';	
import './AboutPage.css';

const imagesURL = 'http://localhost:5000/images';

const AboutPage = () => {

	return (
			
		<div className="AboutPage">
			<Container fluid>
				<marquee direction="left">	<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
									Site is under Construction
								<img id="alert-icon" src={imagesURL + '/assets/alert.png'} />
				</marquee>
			<Row className="App-header">
        			<div id="banner">
                			<img id="icon" src={imagesURL + '/assets/title_icon.png'} alt="logo" />
				</div>
       			 </Row>
        	
            		 <Row>
				<Menu />
			</Row>	
			
		
				<h2 className="title-text">About this Website</h2>
			<div className="textbox">
				<p className='text'>This is a free to use resource currently with over 200 questions over 12 topics.</p>
		
				<p className='text'>It is currently under further development so there will be more questions in future 
						and additional resources.</p>
				<p className='text'>Aiming to align with CCNA 200-301 learning objectives.</p>
			</div>	
		</Container>
     	</div>
	);	
}
export default AboutPage;
