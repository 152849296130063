import React, {useState, useEffect, useRef} from 'react'; 
import { Stage, Layer, Rect, Text, Circle, Line, Group } from 'react-konva';

const OSITest = () => {

	const [drag, setDrag] = useState(false);
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);
	const [stage, setStage] = useState();
	const [answers, setAnswers] = useState(Array(7));
	const [shuffled, setShuffled] = useState(false);


	const  [OSIlayers ,setOSIlayers] = useState(  [{	'layer': "Application", 	'color': "red"},
							   {	'layer': "Presentation" , 	'color': "yellow"},
							   {	'layer': "Session" , 		'color': "blue"},
							   {	'layer': "Transport",  		'color': "purple"},
							   {	'layer': "Network" ,		'color': "orange"},
							   {	'layer': "Data Link",  		'color': "cyan"},
							   {	'layer': "Physical", 		'color': "green"}
															]);

	if(!shuffled){
		const added = [];
		const nextOSIlayers = [];
		while(nextOSIlayers.length < 7){
			const index = Math.floor(Math.random() * 7);
			if(added.includes(index)) continue;
			added.push(index);
			nextOSIlayers.push(OSIlayers[index])		
		}
		setOSIlayers(nextOSIlayers);
		setShuffled(true);	
	}

	const targetRefs = useRef([]);
	const choicesRefs = useRef([]);

	let targetBoxX = window.innerWidth * 0.6;
	let targetBoxY = window.innerHeight * 0.2;
	const margin = window.innerHeight * 0.01;

	const boxWidth = window.innerWidth * 0.12;
	const boxHeight = window.innerHeight * 0.05;
	
	const resetChoicePosition = (name) => {
		console.log(choicesRefs.current)
		console.log(name)
		choicesRefs.current.map((choice) => {
			if( name === choice.attrs.id){
				console.log(choice)
				console.log(choiceBoxesInitialPositions)
				const index = choiceBoxesInitialPositions.findIndex((i) => i.layer === name)
				
				choice.children[0].absolutePosition({x: choiceBoxesInitialPositions[index].x  , y: choiceBoxesInitialPositions[index].y});
				choice.children[1].absolutePosition({x: choiceBoxesInitialPositions[index].x + 20 , y: choiceBoxesInitialPositions[index].y + 15});
			}	
		});
	}


	const removeAnswerByIndex = (index) => {
		const nextAnswers = [];
		answers.map((answer, i) => {
			if(i != index) nextAnswers.push(answer);
		});	
		setAnswers(nextAnswers);
	}

	const removeAnswerByValue = (value) => {
		const nextAnswers = Array(7);
		answers.map((answer, index) => {
			console.log(value, answer)
			if(answer !== value) nextAnswers[index]= answer;
		}, value);
		setAnswers(nextAnswers);
	}

	const onDrag = (name) => {
		setDrag(true);
		removeAnswerByValue(name);		
	}

	const checkForDrop = (event) =>{
	
		
		const choice  = event.target
		console.log(event)
		//console.log(choice)
		console.log(choice.attrs.id)

		const x = event.evt.x;
		const y = event.evt.y;
		
		//console.log(event)
		console.log("mouse: ", event.evt.x, event.evt.y)

		let hit = null;
		 targetRefs.current.map((target, index) =>{
				//console.log(target)
			const rect_x = target.children[0].attrs.x;
			const rect_y = target.children[0].attrs.y;
			const width =  target.children[0].attrs.width;	
			const height =  target.children[0].attrs.height;
			
//			console.log(rect_x, rect_y, width, height)
			if((x > rect_x && x < (rect_x + width))
				&& ((y > rect_y && y < (rect_y + height )))){
				console.log(x, y)
				hit = index+1;		
			}
				
		}, hit);
		

		if(hit){

	
				if(answers[hit-1]) resetChoicePosition(answers[hit-1])
				console.log(targetRefs)
				const targetX = targetRefs.current[hit-1].attrs.x;
				const targetY = targetRefs.current[hit-1].attrs.y;
				console.log(targetX,typeof(targetX), targetY,typeof(targetY));
						
				choice.children[0].absolutePosition({x: targetX, y: targetY});
				choice.children[1].absolutePosition({x: targetX+20, y: targetY+15});
				const newAnswers = Array.from(answers);
				newAnswers[hit-1] =  choice.attrs.id;
				setAnswers(newAnswers);
				console.log(choice.children[0].getAttrs('x'))
				console.log(choice.children[0].getAttrs('y'))
		
		}
	console.log(hit)
		
	}



	let targetBoxes = [];
	for(let i=1;i<8;i++){
		targetBoxes.push({text:"Layer " + i, x:targetBoxX ,y:targetBoxY + ((i-1)*boxHeight) + ((i-1)* margin)})
	}

	let choiceBoxX = 100;
	let choiceBoxY = 200;

	
	const choiceBoxesInitialPositions = [];

	for(let i=0;i<7;i++){


		choiceBoxesInitialPositions.push({layer: OSIlayers[i].layer, color: OSIlayers[i].color, x:choiceBoxX ,y:choiceBoxY + (i*boxHeight + (i*5))})
	}	


  return (
     <Stage id="stage" width={window.innerWidth} height={window.innerHeight}>
	    <Layer id="layer2">
        	<Text text="OSI Model Explained: Arrange the 7 Layers in correct order" fontSize={20} 
			x={100} y={100}/>
		<Group id="targets">
        	{targetBoxes.map((box, index) => {
				//console.log(box.text);
			return(
				<Group key={box.text}>
			        	<Text text={box.text} x={box.x - 100} y={box.y+10} fontFamily='Verdana' fontSize={20}/>
					<Rect x={box.x} y={box.y} width={boxWidth} height={boxHeight}
						 stroke="black" strokeWidth={2} cornerRadius={[10, 10, 10, 10]}
						ref={(el) => {
							//console.log("adding target" , index);
							targetRefs.current[index] = el;}}	/>

				</Group>
			)
		})}
		</Group>
	</Layer>
       <Layer id="layer1">
	{choiceBoxesInitialPositions.map((choice, index) => {
		return(
				<Group key={choice.layer} id={choice.layer} draggable
					  onDragStart={() => {onDrag(choice.layer); }}
					  onDragEnd={(event) => {checkForDrop(event); }}
		  			  ref={(el) => (choicesRefs.current[index] = el)}>
					<Rect width={boxWidth} height={boxHeight} x={choice.x} y={choice.y} opacity={0.5}
						cornerRadius={[10, 10, 10, 10]}
						 fill={choice.color} />	
					<Text text={choice.layer} fontFamily="Verdana" fontSize={16}
						x={choice.x + 20} y={choice.y+15}/>				
				</Group>
			);
		})
      	}
      </Layer>

  
   </Stage>
   );
};

export default OSITest;
