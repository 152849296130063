import React, {useState} from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import Button from 'react-bootstrap/Button';
import FormLabel from 'react-bootstrap/esm/FormLabel';

const RegisterForm = () => {
	
	const submitHandler = () => {

		
	}

	return (
		<Container>
					
			<Row>
				<Form.Text> Register </Form.Text> 
			</Row>
	
			<Row>
				<Col><FormLabel>Username:</FormLabel></Col>
				<Col><Form.Control type='text'></Form.Control></Col>
			</Row>
		
			<Row>
				<Col><FormLabel>Password:</FormLabel></Col>
				<Col><Form.Control type='text'></Form.Control>	</Col>
			</Row>
			<Row>
				<Col><FormLabel>Email:</FormLabel></Col>
				<Col><Form.Control type='text'></Form.Control></Col>
			</Row>

			<Row>
				<Col><Button onClick={submitHandler}>Submit</Button>			</Col>
			</Row>
		</Container>
	);

}


export default RegisterForm;
